import { createRouter, createWebHistory } from "vue-router";
import AccountsView from "../views/AccountsView.vue";
import AccountView from "../views/AccountView.vue";
import NotFound from "../components/NotFound.vue";
import CustomersView from "../views/CustomersView.vue";
import PromptsView from "../views/PromptsView.vue";
import LimitsView from "../views/LimitsView.vue";
import WorkspacesView from "../views/WorkspacesView.vue";
import WorkspaceView from "../views/WorkspaceView.vue";

const routes = [
	{
		path: "/",
		redirect: "/accounts"
	},
	{
		path: "/accounts",
		name: "accounts",
		component: AccountsView,
		props: true
	},
	{
		path: "/accounts/:account_id",
		name: "account",
		component: AccountView,
		props: true
	},
	{
		path: "/customers",
		name: "customers",
		component: CustomersView
	},
	{
		path: "/prompts",
		name: "prompts",
		component: PromptsView
	},
	{
		path: "/limits",
		name: "limits",
		component: LimitsView
	},
	{
		path: "/workspaces",
		name: "workspaces",
		component: WorkspacesView
	},
	{
		path: "/workspaces/:workspace_id",
		name: "workspace",
		component: WorkspaceView
	},
	{
		path: "/:pathMatch(.*)*",
		name: "notFound",
		component: NotFound
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
