<template>
	<div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
		<div class="flex flex-col sm:flex-row flex-1 items-center justify-between">
			<div>
				<p class="text-sm text-gray-700">
					Showing
					<span class="font-medium">{{ pageFrom }}</span>
					to
					<span class="font-medium">{{ pageTo }}</span>
					of
					<span class="font-medium">{{ length }}</span>
					results
				</p>
			</div>

			<div class="mt-2 sm:mt-0">
				<nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
					<a @click="previousPage"
						class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0 cursor-pointer">
						<span class="sr-only">Previous</span>
						<ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
					</a>

					<a v-for="item in paginationItems" :key="item.value" :class="item.class" @click="setCurrentPage(item)">{{ item.value }}</a>

					<a @click="nextPage" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0 cursor-pointer">
						<span class="sr-only">Next</span>
						<ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
					</a>
				</nav>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
</script>

<script>
const paginationItemClass = "relative items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0 inline-flex cursor-pointer";
const selectedPaginationItemClass = "relative z-10 inline-flex items-center bg-aimfox-normal px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aimfox-normal";
const dotsClass = "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0 cursor-default";

export default {
	methods: {
		createPages(pagesLength, currentPage) {
			this.paginationItems = [];

			const dots = {
				type: "dots",
				value: "...",
				class: dotsClass
			};

			if (pagesLength === 0) {
				return this.paginationItems.push({
					type: "number",
					value: 1,
					class: 1 === currentPage ? selectedPaginationItemClass : paginationItemClass
				});
			}

			if (currentPage <= 3) {
				for (let i = 1; i <= 4; i++) {
					this.paginationItems.push({
						type: "number",
						value: i,
						class: i === currentPage ? selectedPaginationItemClass : paginationItemClass
					});

					if (i === pagesLength) {
						return;
					}
				}

				this.paginationItems.push(dots);
			} else if (currentPage < pagesLength - 2) {
				this.paginationItems.push(dots);

				for (let i = currentPage - 1; i < currentPage + 2; i++) {
					this.paginationItems.push({
						type: "number",
						value: i,
						class: i === currentPage ? selectedPaginationItemClass : paginationItemClass
					});
				}

				this.paginationItems.push(dots);
			} else {
				if (pagesLength > 4) {
					this.paginationItems.push(dots);
				}

				for (let i = pagesLength - 3; i <= pagesLength; i++) {
					this.paginationItems.push({
						type: "number",
						value: i,
						class: i === currentPage ? selectedPaginationItemClass : paginationItemClass
					});
				}
			}
		},

		nextPage() {
			if (this.currentPage < this.pagesLength) {
				this.currentPage++;
			}
		},

		previousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
			}
		},

		setCurrentPage(item) {
			if (item.type === "number") {
				this.currentPage = item.value;
			}
		}
	},
	data() {
		return {
			resultsTo: null,
			currentPage: 1,
			paginationItems: []
		};
	},
	props: {
		length: {
			type: Number,
			required: true
		},

		pageSize: {
			type: Number,
			required: true
		},

		pagesLength: {
			type: Number,
			required: true
		}
	},
	watch: {
		length: function (newLength) {
			this.createPages(this.pagesLength, this.currentPage);

			if (newLength < 20) {
				this.resultsTo = newLength;
			} else {
				this.resultsTo = 20;
			}
		},

		currentPage: function (newCurrentPage) {
			this.createPages(this.pagesLength, newCurrentPage);

			this.$emit("change-page", newCurrentPage);
		}
	},
	created() {
		this.createPages(this.pagesLength, 1);
	},
	computed: {
		pageFrom() {
			if (this.length === 0) {
				return 0;
			}

			return ((this.currentPage - 1) * this.pageSize + 1);
		},

		pageTo() {
			return (Math.min(this.currentPage * this.pageSize, this.length));
		}
	}
};
</script>
