<template>
	<div class="bg-gray-900">
		<div class="mx-auto max-w-full">
			<div class="grid grid-cols-2 grid-rows-2 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-5">
				<div v-for="(metric, key) in metricsData" :key="key" class="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8 flex flex-col justify-between">
					<p class="text-sm font-medium leading-6 text-gray-400">{{ metric.label }}</p>

					<p class="mt-2 flex items-baseline gap-x-2">
						<span class="text-4xl font-semibold tracking-tight text-white">{{ metric.value }}</span>
						<span v-if="metric.unit" class="text-sm text-gray-400">{{ metric.unit }}</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		accountsLength: {
			type: Number,
			required: true
		},

		metrics: {
			type: Object,
			required: true
		},

		paidAccounts: {
			type: Number,
			required: true
		},

		activeCampaigns: {
			type: Number,
			required: true
		},

		connectedAccounts: {
			type: Number,
			required: true
		},

		disconnectedAccounts: {
			type: Number,
			required: true
		}
	},
	computed: {
		metricsData() {
			const { sent_connections, accepted_connections, sent_inmails, sent_messages, replies, views } = this.metrics;

			return [
				{ label: "Number Of Accounts", value: this.accountsLength, unit: "(" + this.paidAccounts + " outreach)" },
				{ label: "Connections Sent", value: sent_connections || 0 },
				{ label: "Connections Accepted", value: accepted_connections || 0 },
				{ label: "Inmails Sent", value: sent_inmails || 0 },
				{ label: "Messages Sent", value: sent_messages || 0 },
				{ label: "Replies", value: replies || 0 },
				{ label: "Views", value: views || 0 },
				{ label: "Active Campaigns", value: this.activeCampaigns || 0 },
				{ label: "Connected Accounts", value: this.connectedAccounts || 0 },
				{ label: "Disconnected Accounts", value: this.disconnectedAccounts || 0 }
			];
		}
	}
};
</script>
