<template>
	<WorkspacesComponent
		@search-workspaces="searchWorkspaces"
		@filter-workspaces="filterWorkspaces"
		:workspaces="getFiltered(this.currentPage, this.pageSize)">
	</WorkspacesComponent>
	<Footer
		:length="filteredWorkspaces.length" :pageSize="pageSize"
		:pagesLength="getPagesLength(filteredWorkspaces.length, pageSize)"
		@change-page="changePage">
	</Footer>
</template>

<script>
import WorkspacesComponent from "./workspaces/WorkspacesComponent.vue";
import Footer from "../components/footer/Footer.vue";
import workspacesService from "../services/workspaces";
import { useToast } from "vue-toastification";
import DefaultToast from "../components/toasts/DefaultToast.vue";

export default {
	data() {
		return {
			workspaces: [],
			filteredWorkspaces: [],
			toast: useToast({ timeout: 2000 }),
			currentPage: 1,
			pageSize: 20
		};
	},
	created() {
		return workspacesService.list().then(allWorkspaces => {
			this.workspaces = allWorkspaces;
			this.filteredWorkspaces = allWorkspaces;
		}).catch(() => {
			this.showError({ type: "GetWorkspacesError", message: "Error getting workspaces." });
		});
	},
	components: {
		WorkspacesComponent,
		Footer
	},
	methods: {
		showError(errorObject) {
			this.toast.error({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},
		changePage(page) {
			this.currentPage = page;
		},
		getFiltered(currentPage, pageSize) {
			return this.filteredWorkspaces.slice((currentPage - 1) * pageSize, currentPage * pageSize);
		},
		getPagesLength(workspacesLength, pageSize) {
			return Math.ceil(workspacesLength / pageSize);
		},
		searchWorkspaces(searchData) {
			this.currentPage = 1;

			if (searchData === null) {
				this.filteredWorkspaces = this.workspaces;
			} else {
				this.filteredWorkspaces = this.workspaces.filter(workspace => {
					return workspace.workspace_name.toLowerCase().includes(searchData.toLowerCase()) ||
						workspace.customer_id === searchData.trim() ||
						workspace.workspace_id === searchData.trim();
				});
			}
		},
		filterWorkspaces(activeOnly) {
			if (!activeOnly) {
				return this.filteredWorkspaces = this.workspaces;
			}

			return this.filteredWorkspaces = this.workspaces.filter(workspace => workspace.subscription_status === "active");
		}
	}
};
</script>