import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as Sentry from "@sentry/vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret, faArrowRightFromBracket, faPersonFalling } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret, faArrowRightFromBracket, faPersonFalling);

const app = createApp(App);

Sentry.init({
	app: app,
	dsn: "https://bc83317d0c2768c71f6fc570d15c039d@o769987.ingest.us.sentry.io/4507232218513408",
	integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});

app.use(router);
app.use(Toast);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
