<template>
	<div class="py-4 lg:px-8">
		<div class="flex flex-col sm:flex-row sm:items-center px-4 sm:px-0">
			<div class="sm:flex-auto w-full sm:w-1/2">
				<h1 class="text-base font-semibold leading-6 text-gray-900">Users</h1>

				<p class="mt-2 text-sm text-gray-700">
					A list of all LinkedIn accounts associated with an Aimfox customer.
				</p>
			</div>

			<div class="flex w-full sm:w-1/2 justify-end items-center gap-x-4 mt-4 sm:mt-0 sm:gap-x-4">
				<SwitchComponent @show-outreach="filterOutreach" @reset-accounts="resetOutreach"></SwitchComponent>

				<SearchComponent @search-accounts="emitSearchAccounts"></SearchComponent>
			</div>
		</div>

		<div class="inline-block min-w-full py-2 align-middle">
			<table class="min-w-full divide-y divide-gray-300">
				<thead>
					<tr>
						<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
							<div @click="emitSort({ name: 'full_name' })" class="cursor-pointer">
								<span>Name</span>
								<ArrowDownIcon v-if="sortedBy === 'full_name' && reversed === false"
									class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
								<ArrowUpIcon v-if="sortedBy === 'full_name' && reversed === true"
									class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							</div>
						</th>

						<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
							<div @click="emitSort({ name: 'created_at' })" class="cursor-pointer">
								<span>Created At</span>
								<ArrowDownIcon v-if="sortedBy === 'created_at' && reversed === false"
									class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
								<ArrowUpIcon v-if="sortedBy === 'created_at' && reversed === true"
									class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							</div>
						</th>

						<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:block">
							<div @click="emitSort({ name: 'customer_email' })" class="cursor-pointer">
								<span>Customer Email</span>
								<ArrowDownIcon v-if="sortedBy === 'customer_email' && reversed === false"
									class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
								<ArrowUpIcon v-if="sortedBy === 'customer_email' && reversed === true"
									class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							</div>
						</th>

						<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
							<div @click="emitSort({ name: 'state' })" class="cursor-pointer">
								<span>State</span>
								<ArrowDownIcon v-if="sortedBy === 'state' && reversed === false"
									class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
								<ArrowUpIcon v-if="sortedBy === 'state' && reversed === true"
									class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							</div>
						</th>

						<th scope="col" class="py-3.5 text-sm font-semibold hidden md:block text-gray-900 text-center">
							<div class="flex items-center justify-center mr-8">
								<ComboDropdown @sort-metrics="emitSortMetrics" class="pt-1"></ComboDropdown>

								<div @click="emitSort({ name: 'metrics' })" class="cursor-pointer ml-1">
									<span>Metrics</span>
									<ArrowDownIcon v-if="sortedBy === 'metrics' && reversed === false"
										class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
									<ArrowUpIcon v-if="sortedBy === 'metrics' && reversed === true"
										class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
								</div>
							</div>
						</th>

						<th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
							<span class="sr-only">Edit</span>
						</th>
					</tr>
				</thead>

				<tbody class="divide-y divide-gray-200 bg-white">
					<tr v-for="account in accounts" :key="account.id" class="even:bg-gray-100 hover:shadow-inner">
						<td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
							<div class="flex items-center">
								<div class="relative h-11 w-11 flex-shrink-0">
									<ImpersonateDropdown
    									:src="account.picture_url"
    									:customerId="account.customer_id"
										:accountId="account.id"
    									class="h-11 w-11">
  									</ImpersonateDropdown>

									<CurrencyDollarIcon
										v-if="account.outreach"
										class="absolute top-7 left-7 h-6 w-6 text-green-500 bg-gray-200 border border-gray-300 rounded-full">
									</CurrencyDollarIcon>
								</div>

								<div class="ml-4">
									<router-link
										:to="'/accounts/' + account.id" class="font-medium text-gray-900 hover:underline">
										{{ account.full_name }}
									</router-link>

									<div class="mt-1 text-gray-500">{{ account.email }}</div>
								</div>
							</div>
						</td>

						<td class="whitespace-nowrap px-3 py-5 text-xs hidden sm:table-cell">
							<div class="text-gray-900">{{ new Date(account.created_at).toLocaleString() }}</div>
						</td>

						<td class="whitespace-nowrap px-3 py-5 text-sm hidden sm:block">
							<div class="text-gray-900">{{ account.customer_email }}</div>
						</td>

						<td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500 hidden sm:table-cell">
							<state-badge
								:customClass="account.state === 'LoggedIn' ? 'bg-green-50 text-green-700 ring-green-600/20 py-1 px-2'
								: account.state === 'LoggedOut' ? 'bg-red-50 text-red-700 ring-red-600/20 py-1 px-2'
								: 'bg-gray-50 text-gray-700 ring-gray-600/20 py-1 px-2'">

								<template v-slot:state>{{ account.state }}</template>
							</state-badge>
						</td>

						<td class="hidden sm:block">
							<div class="flex flex-wrap justify-center">
								<template v-for="(item, index) in metricItems.slice(0, 3)" :key="index">
									<MetricComponent :margin-left="item.margin">
										<template v-slot:icon><component :is="item.icon"/></template>

										<template v-slot:text>{{ item.text }}</template>

										<template v-slot:content>
										{{ account.metrics && account.metrics[item.metricKey] ? account.metrics[item.metricKey] : 0 }}
										</template>
									</MetricComponent>
								</template>
							</div>

							<div class="flex flex-wrap justify-center mt-4">
								<template v-for="(item, index) in metricItems.slice(3)" :key="index">
									<MetricComponent :margin-left="item.margin">
										<template v-slot:icon><component :is="item.icon"/></template>

										<template v-slot:text>{{ item.text }}</template>

										<template v-slot:content>
										{{ account.metrics && account.metrics[item.metricKey] ? account.metrics[item.metricKey] : 0 }}
										</template>
									</MetricComponent>
								</template>
							</div>
						</td>

						<td class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium">
							<router-link :to="'/accounts/' + account.id" class="text-aimfox-normal hover:text-aimfox-dark">
								View
							</router-link>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup>
import { ArrowDownIcon, ArrowUpIcon, UsersIcon, UserPlusIcon, EyeIcon, EnvelopeIcon, ArrowUturnLeftIcon, ChatBubbleLeftIcon } from "@heroicons/vue/20/solid";
import { CurrencyDollarIcon } from "@heroicons/vue/24/outline";
</script>

<script>
import ImpersonateDropdown from "../../components/dropdowns/ImpersonateDropdown.vue";
import StateBadge from "../../components/badges/StateBadge.vue";
import MetricComponent from "./components/MetricComponent.vue";
import ComboDropdown from "./components/ComboDropdown.vue";
import SearchComponent from "./components/SearchComponent.vue";
import SwitchComponent from "./components/SwitchComponent.vue";

export default {
	emits: ["show-outreach", "reset-accounts", "sort-accounts", "search-accounts"],
	data() {
		return {
			metricItems: [
				{ icon: UsersIcon, text: "Connections Sent", metricKey: "sent_connections", margin: "-ml-12" },
				{ icon: UserPlusIcon, text: "Connections Accepted", metricKey: "accepted_connections", margin: "-ml-16" },
				{ icon: EyeIcon, text: "Views", metricKey: "views", margin: "-ml-3" },
				{ icon: EnvelopeIcon, text: "Inmails Sent", metricKey: "sent_inmails", margin: "-ml-8" },
				{ icon: ChatBubbleLeftIcon, text: "Messages Sent", metricKey: "sent_messages", margin: "-ml-10" },
				{ icon: ArrowUturnLeftIcon, text: "Replies", metricKey: "replies", margin: "-ml-4" }
			]
		};
	},
	props: {
		accounts: {
			type: Array,
			required: true
		},

		sortedBy: {
			type: String
		},

		reversed: {
			type: Boolean
		}
	},
	methods: {
		emitSort(data) {
			this.$emit("sort-accounts", data);
		},

		emitSearchAccounts(data) {
			this.$emit("search-accounts", data);
		},

		emitSortMetrics(data) {
			this.$emit("sort-accounts", { name: "metrics", filters: data });
		},

		filterOutreach() {
			this.$emit("show-outreach");
		},

		resetOutreach() {
			this.$emit("reset-accounts");
		}
	},
	components: {
		ImpersonateDropdown,
		StateBadge,
		MetricComponent,
		ComboDropdown,
		SearchComponent,
		SwitchComponent
	}
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
	@apply invisible absolute;
}

.has-tooltip:hover .tooltip {
	@apply visible z-50;
}
</style>
