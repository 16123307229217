<template>
    <div class='has-tooltip flex items-center w-16'>
		<span :class="'tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 -mt-12 text-sm ' + marginLeft">
			<slot name="text"></slot>
        </span>

        <div class="h-6 w-5 text-gray-400">
                <slot name="icon"></slot>
        </div>

        <span class="text-sm text-gray-500 px-2">
            <slot name="content"></slot>
        </span>
    </div>
</template>

<script>
export default {
	props: {
		marginLeft: {
			type: String,
			required: true
		}
	}
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
	@apply invisible absolute;
}

.has-tooltip:hover .tooltip {
	@apply visible z-50;
}
</style>
