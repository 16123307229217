<template>
    <div>
		<div class="sm:hidden px-8">
			<label for="tabs" class="sr-only">Select a tab</label>

			<select id="tabs" name="tabs" v-model="selectedTab" @change="switchTab(selectedTab)" class="block w-full rounded-md border-gray-300 focus:border-aimfox-normal focus:ring-aimfox-normal px-4">
				<option v-for="tab in tabs" :key="tab.name" :value="tab.name">{{ tab.name }}</option>
			</select>
		</div>

        <div class="hidden sm:block">
            <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow mx-8" aria-label="Tabs">
                <a v-for="(tab, index) in tabs" :key="tab.name" @click="switchTab(tab.name)" :class="[getTabClass(tab.name), index === 0 ? 'rounded-l-lg' : '', index === tabs.length - 1 ? 'rounded-r-lg' : '']">
                    <span>{{ tab.name }}</span>

                    <span aria-hidden="true" :class="getUnderlineClass(tab.name)"></span>
                </a>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
	emits: ["switch-tab"],
	data() {
		return {
			selectedTab: "Accounts",
			tabs: [
				{ name: "Accounts" },
				{ name: "Subscription" },
				{ name: "Licenses" }
			]
		};
	},
	methods: {
		switchTab(tab) {
			this.selectedTab = tab;

			this.$emit("switch-tab", tab);
		},
		getTabClass(tab) {
			if (this.selectedTab === tab) {
				return "cursor-pointer text-gray-900 group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10";
			} else {
				return "cursor-pointer text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10";
			}
		},
		getUnderlineClass(tab) {
			if (this.selectedTab === tab) {
				return "bg-aimfox-normal absolute inset-x-0 bottom-0 h-0.5";
			} else {
				return "bg-transparent absolute inset-x-0 bottom-0 h-0.5";
			}
		}
	}
};
</script>
