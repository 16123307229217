const axios = require("axios");
const config = require("../config");

module.exports = {
	list: () => {
		return axios.get(`${config.get("HOST")}/api/v1/limits`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.limits;
		});
	},

	update: (limitObject) => {
		return axios.patch(`${config.get("HOST")}/api/v1/limits`, {
			data: limitObject
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data;
		});
	},

	listAccount: (accountId) => {
		return axios.get(`${config.get("HOST")}/api/v1/accounts/${accountId}/limits`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.limits;
		});
	},

	updateAccount: (accountId, limitObject) => {
		return axios.patch(`${config.get("HOST")}/api/v1/accounts/${accountId}/limits`, {
			data: limitObject
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data;
		});
	}
};
