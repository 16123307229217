<template>
	<nav>
		<NavBar @set-timestamp="setTimestamp"></NavBar>
	</nav>

	<router-view :timestamp="this.timestamp" />
</template>

<script>
import NavBar from "./components/nav/NavBar.vue";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/vue";

export default {
	data() {
		return {
			timestamp: "24 hours"
		};
	},
	components: {
		NavBar
	},
	methods: {
		setTimestamp(data) {
			this.timestamp = data;
		}
	},
	beforeCreate() {
		const hash = window.location.hash;

		if (hash) {
			const token = hash.substring(1);

			if (token !== "") {
				const { slack_image, user_role } = jwtDecode(token);

				localStorage.setItem("auth", token);
				localStorage.setItem("userImage", slack_image);
				localStorage.setItem("userRole", user_role);
			}
		}

		const authToken = localStorage.getItem("auth");

		if (authToken) {
			const email = (jwtDecode(authToken)).email;

			Sentry.setUser({
				email
			});
		}
	}
};
</script>

<style>
.Vue-Toastification__toast {
    padding: 16px 24px !important;
}
</style>
