const axios = require("axios");
const config = require("../config");

const timeObject = {
	"24 hours": {
		value: 86400000,
		bucket: "1 hour"
	},
	"7 days": {
		value: 604800000,
		bucket: "1 day"
	},
	"15 days": {
		value: 1296000000,
		bucket: "1 day"
	},
	"30 days": {
		value: 2592000000,
		bucket: "1 day"
	}
};

module.exports = {
	get: (timestamp) => {
		const timestampFrom = Date.now() - timeObject[timestamp].value;
		const timestampTo = Date.now();

		return axios.post(config.get("HOST") + "/api/v1/metrics", {
			data: {
				timestampFrom,
				timestampTo,
				bucket: timeObject[timestamp].bucket
			}
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			const allMetrics = response.data.metrics;

			const data = allMetrics.reduce((acc, bucket) => {
				acc.sent_connections += bucket.interactions.sent_connections;
				acc.accepted_connections += bucket.interactions.accepted_connections;
				acc.sent_messages += bucket.interactions.sent_messages;
				acc.sent_inmails += bucket.interactions.sent_inmails;
				acc.replies += bucket.interactions.replies;
				acc.views += bucket.interactions.views;

				return acc;
			}, {
				sent_connections: 0,
				accepted_connections: 0,
				sent_messages: 0,
				sent_inmails: 0,
				replies: 0,
				views: 0
			});

			return { metrics: allMetrics, calculatedMetrics: data };
		});
	},

	getAccount: (account_id, timestamp) => {
		const timestampFrom = Date.now() - timeObject[timestamp].value;
		const timestampTo = Date.now();

		return axios.post(config.get("HOST") + `/api/v1/metrics/${account_id}`, {
			data: {
				timestampFrom,
				timestampTo,
				bucket: timeObject[timestamp].bucket
			}
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.metrics;
		});
	}
};
