<template>
	<div>
		<Menu as="div" class="relative inline-block text-left">
			<div>
				<MenuButton @click="getImpersonateUrl()" class="focus:ring-aimfox-normal focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full">
					<div class="h-11 w-11 flex-shrink-0 cursor-pointer ">
						<img class="h-11 w-11 rounded-full pointer-events-none" :src=src :alt="customerId + ' Profile Picture'" />
					</div>
				</MenuButton>
			</div>

			<transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
				leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
				<MenuItems class="absolute left-0 z-20 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div class="py-1">
						<MenuItem v-slot="{ active }">
							<a :href=url target="_blank"
								:class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']">
								<font-awesome-icon icon="user-secret" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								Impersonate
							</a>
						</MenuItem>

						<MenuItem v-slot="{ active }">
							<div @click="copyUrl"
								:class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
								<DocumentDuplicateIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								Copy URL
							</div>
						</MenuItem>

						<MenuItem v-slot="{ active }">
							<div @click="copyToken"
								:class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
								<KeyIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								Copy Token
							</div>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>
	</div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { DocumentDuplicateIcon, KeyIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import copy from "copy-to-clipboard";
import impersonateService from "../../../services/impersonate";
import { useToast } from "vue-toastification";
import DefaultToast from "../../../components/toasts/DefaultToast.vue";

export default {
	data() {
		return {
			url: null,
			toast: useToast({ timeout: 2000 }),
			token: null
		};
	},
	props: {
		src: {
			type: String,
			required: true
		},
		customerId: {
			type: String,
			required: true
		},
		workspaceId: {
			type: String,
			required: true
		}
	},
	methods: {
		getImpersonateUrl: async function () {
			return impersonateService.getUrl(this.customerId).then(response => {
				const splitUrl = response.split("#");

				this.token = splitUrl[1];
				this.url = `${splitUrl[0]}workspaces/${this.workspaceId}/home#${splitUrl[1]}`;
			}).catch(error => {
				console.log(error);

				this.showError(error.response.data.error);
			});
		},

		copyUrl() {
			if (!this.url) {
				return this.showError({ type: "UrlError", message: "Error getting account url." });
			}

			copy(this.url);

			return this.showSuccess({ type: "Success", message: "Successfully copied url." });
		},

		copyToken() {
			if (!this.url) {
				return this.showError({ type: "UrlError", message: "Error getting account url." });
			}

			const token = this.url.split("#");

			copy(token[1]);

			return this.showSuccess({ type: "Success", message: "Successfully copied token." });
		},

		showError(errorObject) {
			this.toast.error({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		showSuccess(successObject) {
			this.toast.success({
				component: DefaultToast,
				props: {
					title: `${successObject.type}!` || "UnknownError!",
					body: successObject.message || "An error has occurred."
				}
			});
		}
	}
};
</script>
