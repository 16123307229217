<template>
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 border-t-2 border-gray-300/30">
        <div class="text-center pt-28">
            <div>
				<font-awesome-icon icon="person-falling" class="h-24 w-24 pb-8 text-aimfox-normal cursor-help" shake v-if="hover" v-on:mouseleave="hover = false"/>
                <font-awesome-icon icon="person-falling" class="h-24 w-24 pb-8 text-aimfox-normal" v-else v-on:mouseover="hover = true"/>
            </div>

			<div>
				<p class="text-lg font-semibold text-aimfox-normal">404</p>
            	<h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page Not Found</h1>
           		<p class="mt-6 text-base leading-7 text-gray-600">Sorry, this page doesn't exist.</p>
			</div>

            <div class="mt-10 flex items-center justify-center gap-x-6">
                <a href="/" class="rounded-md bg-aimfox-normal px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-aimfox-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aimfox-normal">
                    Go back home
                </a>
            </div>
        </div>
    </main>
</template>

<script>
export default {
	data() {
		return {
			hover: false
		};
	}
};
</script>
