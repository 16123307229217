<template>
    <div class="border-t border-gray-100 px-4 py-6 flex items-center">
        <div class="flex-1 max-w-md">
            <dt class="text-base font-medium leading-6 text-gray-900">
                <slot name="label"></slot>
            </dt>

            <div class="flex items-center">
                <dd class="mt-1 text-sm leading-6 text-gray-700">
                    <slot name="value"></slot>
                </dd>

                <DocumentDuplicateIcon class="h-5 w-5 ml-2 cursor-pointer text-aimfox-normal" @click="copySlot"></DocumentDuplicateIcon>
            </div>
        </div>
    </div>
</template>

<script setup>
import { DocumentDuplicateIcon } from "@heroicons/vue/24/outline";
</script>

<script>
export default {
	emits: ["copy-slot"],
	methods: {
		copySlot() {
			return this.$emit("copy-slot");
		}
	}
};
</script>
