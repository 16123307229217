<template>
	<div class="container">
		<h1 class="text-xl">{{ this.title }}</h1>
		<span>{{ this.body }}</span>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},

		body: {
			type: String,
			required: true
		}
	}
};
</script>
