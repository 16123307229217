const axios = require("axios");
const config = require("../config");

module.exports = {
	refreshAccountData: (accountId) => {
		return axios.get(`${config.get("HOST")}/api/v1/accounts/${accountId}/refresh`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.account;
		});
	},

	listActive: () => {
		return axios.get(config.get("HOST") + "/api/v1/accounts/active", {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.accounts;
		});
	},

	getAccount: (accountId) => {
		return axios.get(config.get("HOST") + `/api/v1/accounts/${accountId}`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.account;
		});
	},

	getAccountWorkspace: (accountId) => {
		return axios.get(config.get("HOST") + `/api/v1/accounts/${accountId}/workspace`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.workspace;
		});
	}
};
