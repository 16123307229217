<template>
	<div class="px-0 py-0 lg:px-8">
		<div class="inline-block min-w-full py-2 align-middle">
			<table class="min-w-full divide-y divide-gray-300">
				<thead>
					<tr>
						<th scope="col"
							class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0 cursor-pointer"
							@click="sortCustomers('full_name')">Full Name
							<ArrowDownIcon v-if="sortedBy === 'full_name' && reversed === false"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							<ArrowUpIcon v-if="sortedBy === 'full_name' && reversed === true"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
						</th>

						<th scope="col"
							class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hidden sm:block"
							@click="sortCustomers('created_at')">Created At
							<ArrowDownIcon v-if="sortedBy === 'created_at' && reversed === false"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							<ArrowUpIcon v-if="sortedBy === 'created_at' && reversed === true"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
						</th>

						<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
							@click="sortCustomers('email')">Email
							<ArrowDownIcon v-if="sortedBy === 'email' && reversed === false"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							<ArrowUpIcon v-if="sortedBy === 'email' && reversed === true"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
						</th>

						<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
							@click="sortCustomers('total_license_count')">Licenses
							<ArrowDownIcon v-if="sortedBy === 'total_license_count' && reversed === false"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							<ArrowUpIcon v-if="sortedBy === 'total_license_count' && reversed === true"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
						</th>

						<th scope="col"
							class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer hidden sm:block"
							@click="sortCustomers('chargebee_id')">Chargebee ID
							<ArrowDownIcon v-if="sortedBy === 'chargebee_id' && reversed === false"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
							<ArrowUpIcon v-if="sortedBy === 'chargebee_id' && reversed === true"
								class="inline-block h-5 w-5 text-gray-400" aria-hidden="true" />
						</th>

						<th scope="col">
							<SearchComponent @search-customers="searchCustomers" class="font-normal"></SearchComponent>
						</th>
					</tr>
				</thead>

				<tbody class="bg-white">
					<tr v-for="customer in tableCustomers" :key="customer.email"
						class="even:bg-gray-100 hover:shadow-inner">
						<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{{ customer.full_name }}</td>
						<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ new Date(customer.created_at * 1000).toLocaleString() }}</td>
						<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ customer.email }}</td>
						<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ customer.total_license_count }}</td>
						<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ customer.chargebee_id }}</td>

						<td class="px-3">
							<ImpersonateDropdown
								@copy-url="copyUrl"
								@copy-token="copyToken"
								@edit-customer="emitEdit"
								@open-licenses-modal="emitAddLicenses"
								:customerId="customer.id"
								:chargebeeId="customer.chargebee_id">
							</ImpersonateDropdown>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup>
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import ImpersonateDropdown from "./ImpersonateDropdown.vue";
import SearchComponent from "./SearchComponent.vue";

export default {
	emits: ["copy-url", "copy-token", "search-customers", "edit-customer", "sort-customers", "add-licenses"],
	props: {
		tableCustomers: {
			type: Array,
			required: true
		},
		sortedBy: {
			type: String
		},
		reversed: {
			type: Boolean
		}
	},
	components: {
		ImpersonateDropdown,
		SearchComponent
	},
	methods: {
		emitAddLicenses(chargebeeId) {
			this.$emit("add-licenses", chargebeeId);
		},

		copyUrl(url) {
			this.$emit("copy-url", url);
		},

		copyToken(url) {
			this.$emit("copy-token", url);
		},

		emitEdit(chargebeeId) {
			this.$emit("edit-customer", chargebeeId);
		},

		sortCustomers(sortData) {
			this.$emit("sort-customers", sortData);
		},

		searchCustomers(searchData) {
			this.$emit("search-customers", searchData);
		}
	}
};
</script>
