<template>
	<div class="h-96">
		<Line :data="graphData" :options="graphOptions" />
	</div>
</template>

<script setup>
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
</script>

<script>
export default {
	name: "MetricsChart",
	components: {
		Line
	},
	props: {
		rawMetrics: {
			type: Object,
			required: true
		}
	},
	methods: {
		sortMetrics() {
			let metrics = this.rawMetrics.metrics;

			const optionsObject = {
				timeZone: "CET",
				[ this.rawMetrics.timestamp === "24 hours" ? "hour" : "day" ] : "numeric",
				[ this.rawMetrics.timestamp === "24 hours" ? "minute" : "month" ] : "numeric"
			};

			let data = metrics.map(element => {
				let timestamp = new Date(element.timestamp).toLocaleString("en-gb", optionsObject);

				return {
					timestamp: timestamp,
					sentConnections: element.sent_connections,
					acceptedConnections: element.accepted_connections,
					sentMessages: element.sent_messages,
					sentInmails: element.sent_inmails,
					replies: element.replies,
					views: element.views
				};
			});

			this.graphData = {
				labels: data.map(item => item.timestamp),
				datasets: [
					{
						label: "Sent Connections",
						borderColor: "#3b82f6",
						backgroundColor: "#3b82f6",
						data: data.map(item => item.sentConnections),
						tension: 0.3,
						pointRadius: 0
					},
					{
						label: "Accepted Connections",
						borderColor: "#34d399",
						backgroundColor: "#34d399",
						data: data.map(item => item.acceptedConnections),
						tension: 0.3,
						pointRadius: 0
					},
					{
						label: "Sent Messages",
						borderColor: "#22d3ee",
						backgroundColor: "#22d3ee",
						data: data.map(item => item.sentMessages),
						tension: 0.3,
						pointRadius: 0
					},
					{
						label: "Sent Inmails",
						borderColor: "#8b5cf6",
						backgroundColor: "#8b5cf6",
						data: data.map(item => item.sentInmails),
						tension: 0.3,
						pointRadius: 0
					},
					{
						label: "Replies",
						borderColor: "#fb923c",
						backgroundColor: "#fb923c",
						data: data.map(item => item.replies),
						tension: 0.3,
						pointRadius: 0
					},
					{
						label: "Views",
						borderColor: "#ef4444",
						backgroundColor: "#ef4444",
						data: data.map(item => item.views),
						tension: 0.3,
						pointRadius: 0
					}
				]
			};

			this.graphOptions = {
				responsive: true,
				maintainAspectRatio: false,
				interaction: {
					intersect: false
				}
			};
		}
	},
	created() {
		this.sortMetrics();
	},
	data() {
		return {
			graphData: {},
			graphOptions: {}
		};
	},
	watch: {
		rawMetrics() {
			this.sortMetrics();
		}
	}
};
</script>
