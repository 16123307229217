<template>
    <div class="w-full max-w-lg lg:max-w-xs">
        <label for="search" class="sr-only">Search</label>

        <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon class="h-5 w-5 text-white" aria-hidden="true" />
            </div>

            <input
                id="search"
                name="search"
                class="block w-full rounded-md border-0 bg-gray-800 py-1.5 pl-10 pr-3 text-white ring-1 ring-inset ring-white placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-aimfox-normal sm:text-sm sm:leading-6"
                placeholder="Search"
                type="search"
                v-model="searchText" />
        </div>
    </div>
</template>

<script setup>
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
</script>

<script>
export default {
	emits: ["search-workspaces"],
	watch: {
		searchText(newData) {
			this.emitSearchWorkspaces(newData);
		}
	},
	data() {
		return {
			searchText: null
		};
	},
	methods: {
		emitSearchWorkspaces(data) {
			this.$emit("search-workspaces", data);
		}
	}
};
</script>
