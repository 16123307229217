<template>
	<div>
		<button type="button" class="inline-flex items-center" @click="toggleFormVisibility">
			<Bars3Icon class="h-8 w-8 text-gray-700"></Bars3Icon>
		</button>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <div class="absolute z-20 mt-14 flex left-24" v-if="showForm">
                <div class="h-screen w-full fixed left-0 top-0 z-10" @click="toggleFormVisibility"></div>

                <div class="max-w-full flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 z-20">
                    <div>
                        <router-link to="/">
                            <div class="group relative flex items-center gap-x-4 rounded-lg p-4 hover:bg-gray-100 cursor-pointer" @click="toggleFormVisibility">
                                <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white shadow-md group-hover:border border-aimfox-ultralight">
                                    <UsersIcon class="h-7 w-7 text-gray-600 group-hover:text-aimfox-normal"></UsersIcon>
                                </div>

                                <div class="flex items-center pr-2">
                                    <a href="/accounts" class="font-semibold text-gray-900">Accounts</a>
                                </div>
                            </div>
                        </router-link>

                        <router-link to="/customers">
                            <div class="group relative flex items-center gap-x-4 rounded-lg p-4 hover:bg-gray-100 cursor-pointer" @click="toggleFormVisibility">
                                <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white shadow-md group-hover:border border-aimfox-ultralight">
                                    <IdentificationIcon class="h-7 w-7 text-gray-600 group-hover:text-aimfox-normal"></IdentificationIcon>
                                </div>

                                <div class="flex items-center pr-2">
                                    <a href="/customers" class="font-semibold text-gray-900">Customers</a>
                                </div>
                            </div>
                        </router-link>

                        <router-link to="/prompts" v-if="userRole === 'developer'">
                            <div class="group relative flex items-center gap-x-4 rounded-lg p-4 hover:bg-gray-100 cursor-pointer" @click="toggleFormVisibility">
                                <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white shadow-md group-hover:border border-aimfox-ultralight">
                                    <CommandLineIcon class="h-7 w-7 text-gray-600 group-hover:text-aimfox-normal"></CommandLineIcon>
                                </div>

                                <div class="flex items-center pr-2">
                                    <a href="/prompts" class="font-semibold text-gray-900">AI Prompts</a>
                                </div>
                            </div>
                        </router-link>

                        <router-link to="/limits" v-if="userRole === 'developer'">
                            <div class="group relative flex items-center gap-x-4 rounded-lg p-4 hover:bg-gray-100 cursor-pointer" @click="toggleFormVisibility">
                                <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white shadow-md group-hover:border border-aimfox-ultralight">
                                    <AdjustmentsVerticalIcon class="h-7 w-7 text-gray-600 group-hover:text-aimfox-normal"></AdjustmentsVerticalIcon>
                                </div>

                                <div class="flex items-center pr-2">
                                    <a href="/limits" class="font-semibold text-gray-900">Limits</a>
                                </div>
                            </div>
                        </router-link>

                        <router-link to="/workspaces">
                            <div class="group relative flex items-center gap-x-4 rounded-lg p-4 hover:bg-gray-100 cursor-pointer" @click="toggleFormVisibility">
                                <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white shadow-md group-hover:border border-aimfox-ultralight">
                                    <UserGroupIcon class="h-7 w-7 text-gray-600 group-hover:text-aimfox-normal"></UserGroupIcon>
                                </div>

                                <div class="flex items-center pr-2">
                                    <a href="/workspaces" class="font-semibold text-gray-900">Workspaces</a>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="bg-gray-50 hover:bg-gray-100 cursor-pointer hover:shadow-inner" @click="toggleFormVisibility">
                        <span class="flex items-center justify-center p-3 font-semibold text-gray-900">Close</span>
                    </div>
                </div>
            </div>
        </transition>
	</div>
</template>

<script setup>
import { Bars3Icon } from "@heroicons/vue/24/solid";
import { CommandLineIcon, IdentificationIcon, UsersIcon, AdjustmentsVerticalIcon, UserGroupIcon } from "@heroicons/vue/24/outline";
</script>

<script>
export default {
	data() {
		return {
			showForm: false,
			userRole: localStorage.getItem("userRole")
		};
	},
	methods: {
		toggleFormVisibility() {
			this.showForm = !this.showForm;
		}
	}
};
</script>
