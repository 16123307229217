<template>
	<div class="space-y-10 divide-gray-900/10 sm:px-14 px-8 py-12 bg-gray-100 shadow-inner h-screen">
		<div class="px-4">
			<h2 class="text-4xl font-semibold leading-7 text-gray-900">Interaction Limits</h2>
			<p class="mt-4 text-base leading-6 text-gray-600">
				Adjust weekly limits and set specific limits for different types of interactions</p>
		</div>

		<form>
			<div class="border border-gray-300 p-4 space-y-4 rounded-xl bg-white shadow-xl">
				<div class="border-b border-gray-900/10 pb-8">
					<div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
						<div class="sm:col-span-full">
							<label for="connect" class="block text-md font-medium text-gray-900 mb-2">
								Connect
							</label>

							<input v-model.number="limitObject.connect" type="number" name="connect" id="connect"
								min="1" max="1000"
								@input="validateInput('connect')"
								class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aimfox-normal sm:text-sm sm:leading-6">
						</div>
					</div>

					<div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
						<div class="sm:col-span-full">
							<label for="email_connect" class="block text-md font-medium text-gray-900 mb-2">
								Email Connect
							</label>

							<input v-model.number="limitObject.email_connect" type="number" name="email_connect"
								id="email_connect" min="1" max="1000"
								@input="validateInput('email_connect')"
								class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aimfox-normal sm:text-sm sm:leading-6">
						</div>
					</div>

					<div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
						<div class="sm:col-span-full">
							<label for="message_request" class="block text-md font-medium text-gray-900 mb-2">
								Message Request
							</label>

							<input v-model.number="limitObject.message_request" type="number" name="message_request"
								id="message_request" min="1" max="1000"
								@input="validateInput('message_request')"
								class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aimfox-normal sm:text-sm sm:leading-6">
						</div>
					</div>

					<div class="mt-10 grid grid-cols-1 gap-x-6 sm:grid-cols-6">
						<div class="sm:col-span-full">
							<label for="inmail" class="block text-md font-medium text-gray-900 mb-2">
								Inmail
							</label>

							<input v-model.number="limitObject.inmail" type="number" name="inmail" id="inmail" min="1"
								max="1000"
								@input="validateInput('inmail')"
								class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aimfox-normal sm:text-sm sm:leading-6">
						</div>
					</div>
				</div>

				<div class="flex items-center justify-end gap-x-6">
					<button type="button"
						class="w-1/2 rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aimfox-normal"
						@click="resetForm()">
						Reset
					</button>

					<button type="button"
						class="w-1/2 rounded-md bg-aimfox-normal px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-aimfox-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aimfox-normal"
						@click="updateLimits()">
						Save
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { useToast } from "vue-toastification";
import DefaultToast from "../components/toasts/DefaultToast.vue";
import limitService from "../services/limits";

export default {
	data() {
		return {
			toast: useToast({ timeout: 2000 }),
			activeLimits: {},
			limitObject: {}
		};
	},
	created() {
		return limitService.list().then(limits => {
			this.activeLimits = JSON.parse(JSON.stringify(limits));
			this.limitObject = JSON.parse(JSON.stringify(limits));
		}).catch(() => {
			this.showError({ type: "GetLimitsError", message: "Error getting limits." });
		});
	},
	methods: {
		updateLimits() {
			return limitService.update(this.limitObject).then(() => {
				this.showSuccess({ type: "Success", message: "Updated limits." });
			}).catch(() => {
				this.showError({ type: "UpdateLimitsError", message: "Error updating limits." });
			});
		},

		resetForm() {
			this.limitObject = JSON.parse(JSON.stringify(this.activeLimits));
		},

		showError(errorObject) {
			this.toast.error({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		showSuccess(toastObject) {
			this.toast.success({
				component: DefaultToast,
				props: {
					title: `${toastObject.type}!` || "UnknownError!",
					body: toastObject.message || "An error has occurred."
				}
			});
		},

		validateInput(field) {
			if (this.limitObject[field] < 1) {
				this.limitObject[field] = 1;
			} else if (this.limitObject[field] > 1000) {
				this.limitObject[field] = 1000;
			}
		}
	}
};
</script>
