<template>
	<div class="px-4 sm:px-0 py-6 flex flex-col sm:flex-row sm:items-center">
		<div class="pl-8 inline-block h-full cursor-pointer sm:pb-0 pb-4">
			<img v-if="this.account" :src="this.account.picture_url" alt="Account Image" class="w-auto h-16 rounded-full"
				@dblclick="openPicture(this.account.picture_url)" />
		</div>

		<div class="inline-block pl-6">
			<h3 class="text-3xl font-medium leading-6 text-gray-900 pb-2">Account Information</h3>
			<p class="text-lg font-medium leading-6 text-gray-500">
				Comprehensive Personal Details, Subscription Information and Campaign Insights.
			</p>
		</div>

		<ImpersonateButtons
			class="mt-4 sm:mt-0"
			v-if="this.account"
			@show-toast="handleToast"
			:customerId="account.customer_id"
			:workspace-id="accountWorkspace.id">
		</ImpersonateButtons>
	</div>

	<div class="border-b border-gray-200 pb-0 px-8">
		<div class="mt-4 hidden sm:block">
			<div>
				<nav class="-mb-px flex space-x-8">
					<a v-for="tab in tabs" :key="tab.name" :class="getTabClass(tab.name)"
						:aria-current="tab.current ? 'page' : undefined" @click="this.selectedTab = tab.name">
						<p>{{ tab.name }}</p>
					</a>
				</nav>
			</div>
		</div>

		<TabDropdown :propTabs="this.tabs" class="sm:hidden pb-3" @change-tab="changeTab"></TabDropdown>
	</div>

	<InfoView v-if="this.selectedTab === 'Info'" :account="this.account" @show-toast="handleToast"></InfoView>
	<MetricsView v-if="this.selectedTab === 'Metrics'" :raw-metrics="this.rawMetrics"></MetricsView>
	<CampaignsView v-if="this.selectedTab === 'Campaigns'" :campaigns="this.allCampaigns"></CampaignsView>
	<SubscriptionView v-if="this.selectedTab === 'Subscription'" :subscription="this.subscription"></SubscriptionView>
	<LimitsView v-if="this.selectedTab === 'Limits'" :limits="this.accountLimits" @show-toast="handleToast"></LimitsView>
</template>

<script>
import InfoView from "./account/InfoView.vue";
import MetricsView from "./account/MetricsView.vue";
import SubscriptionView from "./account/SubscriptionView.vue";
import metricsService from "../services/metrics";
import LimitsView from "./account/LimitsView.vue";
import limitsService from "../services/limits";
import accountsService from "../services/accounts";
import CampaignsView from "./account/CampaignsView.vue";
import { useToast } from "vue-toastification";
import DefaultToast from "../components/toasts/DefaultToast.vue";
import ImpersonateButtons from "./account/components/ImpersonateButtons.vue";
import TabDropdown from "./account/components/TabDropdown.vue";

export default {
	components: {
		InfoView,
		MetricsView,
		SubscriptionView,
		CampaignsView,
		LimitsView,
		ImpersonateButtons,
		TabDropdown
	},
	data() {
		return {
			selectedTab: null,
			tabs: [
				{ name: "Info" },
				{ name: "Metrics" },
				{ name: "Campaigns" },
				{ name: "Subscription" },
				{ name: "Limits" }
			],
			account: null,
			subscription: null,
			allCampaigns: null,
			accountWorkspace: null,
			rawMetrics: null,
			accountLimits: null,
			toast: useToast({ timeout: 2000 }),
			userRole: localStorage.getItem("userRole")
		};
	},
	props: {
		timestamp: {
			type: String,
			required: true
		},

		account_id: {
			type: String,
			required: true
		}
	},
	methods: {
		openPicture(url) {
			window.open(url, "_blank");
		},

		getTabClass(tab) {
			if (this.selectedTab === tab) {
				return "border-aimfox-light text-aimfox-normal whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer";
			} else {
				return "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer";
			}
		},

		getAccount: async function (account_id) {
			return accountsService.getAccount(account_id).then(account => {
				const { profile, subscription, campaigns, workspace } = account;

				if (subscription) {
					subscription.created_at = new Date(subscription.created_at * 1000).toLocaleString() + ` - ${subscription.created_at * 1000}`;
					subscription.updated_at = new Date(subscription.updated_at * 1000).toLocaleString() + ` - ${subscription.updated_at * 1000}`;
					subscription.cancelled_at = subscription.cancelled_at !== 0 ? new Date(subscription.cancelled_at * 1000).toLocaleString() + ` - ${subscription.cancelled_at * 1000}` : 0;
					subscription.next_billing = new Date(subscription.next_billing * 1000).toLocaleString() + ` - ${subscription.next_billing * 1000}`;
				}

				this.account = profile;
				this.subscription = subscription || {};
				this.allCampaigns = campaigns;
				this.accountWorkspace = workspace;
				this.selectedTab = "Info";
			}).catch(error => {
				this.showError(error.response.data.error);

				console.error(error);
			});
		},

		showError(errorObject) {
			this.toast.error({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		showSuccess(errorObject) {
			this.toast.success({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		handleToast(data) {
			if (data.status === "error") {
				this.showError(data);
			} else {
				this.showSuccess(data);
			}
		},

		changeTab(data) {
			this.selectedTab = data.name;
		}
	},
	created() {
		const accountPromise = this.getAccount(this.$route.params.account_id);

		const metricsPromise = metricsService.getAccount(this.$route.params.account_id, this.timestamp)
			.then(response => {
				this.rawMetrics = { timestamp: this.timestamp, metrics: response };
			}).catch(error => {
				this.showError(error.response.data.error);

				console.error(error);
			});

		const limitsPromise = limitsService.listAccount(this.$route.params.account_id)
			.then(limitsResponse => {
				this.accountLimits = limitsResponse;
			}).catch(error => {
				this.showError(error.response.data.error);

				console.error(error);
			});

		return Promise.all([accountPromise, metricsPromise, limitsPromise]);
	},
	watch: {
		timestamp(newTimestamp) {
			return metricsService.getAccount(this.$route.params.account_id, newTimestamp).then(response => {
				this.rawMetrics = { timestamp: this.timestamp, metrics: response };
			}).catch(error => {
				this.showError(error.response.data.error);

				console.error(error);
			});
		}
	}
};
</script>
