const axios = require("axios");
const config = require("../config");

module.exports = {
	getUrl: () => {
		return axios.get(config.get("HOST") + "/api/v1/oauth/redirect")
			.then(response => {
				return response.data.url;
			});
	}
};
