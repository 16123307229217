<template>
	<header>
		<div class="flex flex-col bg-gray-800 p-8">
			<div class="flex flex-col sm:flex-row items-center justify-between mb-4">
				<div class="min-w-0 flex-1">
					<h2 v-if="workspaceInfo" class="font-bold leading-7 text-white truncate text-4xl tracking-tight py-2">
						{{ workspaceInfo.name }}
					</h2>

					<p class="font-bold leading-7 text-white truncate text-sm tracking-tight">{{ workspaceInfo.id }}</p>
				</div>

				<ImpersonateButtons class="mt-4 sm:mt-0" @show-toast="handleToast" :customer-id="customerId" :workspace-id="workspaceInfo.id"></ImpersonateButtons>
			</div>

			<div class="flex flex-col sm:flex-row sm:flex-wrap sm:space-x-6">
				<div class="mt-2 flex items-center text-sm text-gray-300">
					<CreditCardIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
					{{ workspaceInfo.license_count }} Licenses
				</div>
				<div class="mt-2 flex items-center text-sm text-gray-300">
					<CurrencyDollarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
					{{ workspaceInfo.active_license_count }} Active licenses
				</div>
				<div class="mt-2 flex items-center text-sm text-gray-300">
					<ReceiptPercentIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
					{{ workspaceInfo.non_renewing_license_count }} Non renewing licenses
				</div>
				<div class="mt-2 flex items-center text-sm text-gray-300">
					<CalendarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
					{{ new Date(workspaceInfo.created_at * 1000).toLocaleString() }}
				</div>
				<div class="mt-2 flex items-center text-sm text-gray-300">
					<UserCircleIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
					{{ workspaceInfo.member_count }}/{{ workspaceInfo.max_members }} Members
				</div>
			</div>
		</div>
	</header>

	<div class="bg-gray-100 min-h-screen">
		<NavBar class="pt-8" @switch-tab="changeTab"></NavBar>

		<AccountsView v-if="selectedTab === 'Accounts'" :accounts="workspaceInfo.accounts"></AccountsView>
		<SubscriptionView v-if="selectedTab === 'Subscription'" :subscription="workspaceInfo.subscription"></SubscriptionView>
		<LicensesView
			v-if="selectedTab === 'Licenses'"
			:licenses="workspaceInfo.licenses"
			@show-toast="handleToast"
			:chargebee-id="workspaceInfo.subscription.customer_id"
			@refresh-licenses="refreshLicenses"></LicensesView>
	</div>
</template>

<script setup>
import { CreditCardIcon, CalendarIcon, CurrencyDollarIcon, ReceiptPercentIcon, UserCircleIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import workspacesService from "../services/workspaces";
import { useToast } from "vue-toastification";
import DefaultToast from "../components/toasts/DefaultToast.vue";
import AccountsView from "./workspace/AccountsView.vue";
import SubscriptionView from "./workspace/SubscriptionView.vue";
import LicensesView from "./workspace/LicensesView.vue";
import ImpersonateButtons from "./workspace/components/ImpersonateButtons.vue";
import NavBar from "./workspace/components/NavBar.vue";

export default {
	components: {
		AccountsView,
		SubscriptionView,
		ImpersonateButtons,
		LicensesView,
		NavBar
	},
	data() {
		return {
			toast: useToast({ timeout: 2000 }),
			workspaceInfo: null,
			selectedTab: null,
			customerId: this.$route.query.customer_id
		};
	},
	created() {
		return workspacesService.get(this.$route.params.workspace_id).then(workspace => {
			workspace.subscription.created_at = new Date(workspace.subscription.created_at * 1000).toLocaleString() + ` - ${workspace.subscription.created_at * 1000}`;
			workspace.subscription.updated_at = new Date(workspace.subscription.updated_at * 1000).toLocaleString() + ` - ${workspace.subscription.updated_at * 1000}`;
			workspace.subscription.cancelled_at = workspace.subscription.cancelled_at !== 0 ? new Date(workspace.subscription.cancelled_at * 1000).toLocaleString() + ` - ${workspace.subscription.cancelled_at * 1000}` : 0;
			workspace.subscription.next_billing = new Date(workspace.subscription.next_billing * 1000).toLocaleString() + ` - ${workspace.subscription.next_billing * 1000}`;

			const licensedAccountIds = new Set(workspace.licenses.map(license => license.account_id));

			workspace.accounts.forEach(account => {
				account.license = licensedAccountIds.has(account.id);
			});

			this.workspaceInfo = workspace;
			this.selectedTab = "Accounts";
		}).catch(error => {
			this.showError(error.response.data.error);

			console.error(error);
		});
	},
	methods: {
		handleToast(data) {
			if (data.status === "error") {
				this.showError(data);
			} else {
				this.showSuccess(data);
			}
		},
		changeTab(tab) {
			this.selectedTab = tab;
		},
		showSuccess(errorObject) {
			this.toast.success({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},
		showError(errorObject) {
			this.toast.error({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		}
	}
};
</script>
