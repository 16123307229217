<template>
	<div class="wrapper w-full h-full flex items-center justify-end">
		<Menu as="div" class="relative inline-block align-middle">
			<div>
				<MenuButton @click="getImpersonateUrl" class="bg-gray-700 inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aimfox-normal">
					Actions
					<ChevronDownIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
				</MenuButton>
			</div>

			<transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
				leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
				<MenuItems class="absolute right-0 z-20 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div class="py-1">
						<MenuItem v-slot="{ active }">
							<a :href=url target="_blank"
								:class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']">
								<font-awesome-icon icon="user-secret" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								Impersonate
							</a>
						</MenuItem>

						<MenuItem v-slot="{ active }">
							<div @click="copyUrl"
								:class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
								<DocumentDuplicateIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								Copy URL
							</div>
						</MenuItem>

						<MenuItem v-slot="{ active }">
							<div @click="copyToken"
								:class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
								<KeyIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								Copy Token
							</div>
						</MenuItem>

						<MenuItem v-slot="{ active }">
							<div @click="emitEdit"
								:class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
								<PencilSquareIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								Edit Email
							</div>
						</MenuItem>

						<MenuItem v-slot="{ active }" v-if="userRole !== 'maintainer'">
							<div @click="emitOpenLicensesModal"
								:class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm cursor-pointer']">
								<CreditCardIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								Set Licenses
							</div>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>
	</div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { useToast } from "vue-toastification";
import DefaultToast from "../../../components/toasts/DefaultToast.vue";
</script>

<script>
import { DocumentDuplicateIcon, KeyIcon, ChevronDownIcon, PencilSquareIcon, CreditCardIcon } from "@heroicons/vue/20/solid";
import impersonateService from "../../../services/impersonate";

export default {
	emits: ["copy-url", "edit-customer", "copy-token", "open-licenses-modal"],
	props: {
		customerId: {
			type: String,
			required: true
		},
		chargebeeId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			url: null,
			toast: useToast({ timeout: 2000 }),
			userRole: localStorage.getItem("userRole")
		};
	},
	methods: {
		getImpersonateUrl: async function () {
			return impersonateService.getUrl(this.customerId)
				.then(response => {
					this.url = response;
				}).catch(error => {
					this.showError(error.response.data.error);
				});
		},

		emitOpenLicensesModal() {
			this.$emit("open-licenses-modal", this.chargebeeId);
		},

		copyUrl() {
			if (!this.url) {
				return this.showError({ type: "UrlError", message: "Error getting account url." });
			}

			this.$emit("copy-url", this.url);
		},

		copyToken() {
			if (!this.url) {
				return this.showError({ type: "UrlError", message: "Error getting account url." });
			}

			this.$emit("copy-token", this.url);
		},

		emitEdit() {
			this.$emit("edit-customer", this.chargebeeId);
		},

		showError(errorObject) {
			this.toast.error({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		}
	}
};
</script>
