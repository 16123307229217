const axios = require("axios");
const config = require("../config");

module.exports = {
	list: () => {
		return axios.get(config.get("HOST") + "/api/v1/customers", {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.customers.map(customer => {
				const totalCount = (customer.promotions || []).reduce((acc, promotion) => {
					return acc + (promotion.license_count || 0);
				}, 0);

				return {
					...customer,
					full_name: `${customer.first_name} ${customer.last_name}`,
					total_license_count: totalCount
				};
			});
		});
	},

	updateCustomer: (chargebeeId, customerObject) => {
		return axios.patch(config.get("HOST") + `/api/v1/customers/${chargebeeId}`, {
			data: customerObject
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data;
		});
	},

	addLicenses: (chargebeeId, licenseCount) => {
		return axios.put(config.get("HOST") + `/api/v1/customers/${chargebeeId}/promotions`, {
			data: licenseCount
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data;
		});
	}
};
