<template>
	<Disclosure as="nav" class="bg-white shadow">
		<div class="mx-auto max-w-full px-2 sm:px-4 lg:px-5">
			<div class="flex h-16 justify-between">
				<div class="flex px-2 lg:px-0">
					<div class="flex flex-shrink-0 items-center">
						<a href="/">
							<img class="h-8 w-auto" src="../../../public/aimfox-black.svg" alt="AimFox Logo">
						</a>
					</div>

					<div class="hidden lg:ml-6 lg:flex lg:space-x-8">
						<a href="/accounts" :class="getTabClass('accounts')">
							<router-link to="/">Accounts</router-link>
						</a>
					</div>

					<div class="hidden lg:ml-6 lg:flex lg:space-x-8">
						<a href="/customers" :class="getTabClass('customers')">
							<router-link to="/customers">Customers</router-link>
						</a>
					</div>

					<div v-if="userRole === 'developer'" class="hidden lg:ml-6 lg:flex lg:space-x-8">
						<a href="/prompts" :class="getTabClass('prompts')">
							<router-link to="/prompts">AI Prompts</router-link>
						</a>
					</div>

					<div v-if="userRole === 'developer'" class="hidden lg:ml-6 lg:flex lg:space-x-8">
						<a href="/limits" :class="getTabClass('limits')">
							<router-link to="/limits">Limits</router-link>
						</a>
					</div>

					<div class="hidden lg:ml-6 lg:flex lg:space-x-8">
						<a href="/workspaces" :class="getTabClass('workspaces')">
							<router-link to="/workspaces">Workspaces</router-link>
						</a>
					</div>

					<NavBarDropdown class="lg:hidden ml-6 flex space-x-8"></NavBarDropdown>
				</div>

				<TimeframeDropdown @set-timestamp="setTimestamp"></TimeframeDropdown>

				<SignOutDropdown></SignOutDropdown>
			</div>
		</div>
	</Disclosure>
</template>

<script setup>
import { Disclosure } from "@headlessui/vue";
</script>

<script>
import TimeframeDropdown from "../dropdowns/TimeframeDropdown.vue";
import SignOutDropdown from "../dropdowns/SignOutDropdown.vue";
import NavBarDropdown from "../dropdowns/NavBarDropdown.vue";

export default {
	data() {
		return {
			userRole: localStorage.getItem("userRole")
		};
	},
	methods: {
		setTimestamp(data) {
			this.$emit("set-timestamp", data);
		},

		getTabClass(tab) {
			if (this.$route.path !== `/${tab}`) {
				return "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
			} else {
				return "inline-flex items-center border-b-2 border-aimfox-normal px-1 pt-1 text-sm font-medium text-gray-900";
			}
		}
	},
	components: {
		TimeframeDropdown,
		SignOutDropdown,
		NavBarDropdown
	}
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
