<template>
	<div class="flex-1 sm:p-14 p-8">
		<div class="overflow-x-auto rounded-md shadow-lg">
			<table class="min-w-full divide-y divide-gray-200 border border-gray-200">
				<thead class="bg-gray-400/20">
					<tr>
						<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Account ID</th>
						<th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
						<th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Lifetime</th>
						<th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Expires At</th>
						<th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Source</th>
						<th v-if="userRole !== 'maintainer'" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
							<button @click="openLicenseModal = true" type="button" class="rounded-md bg-aimfox-ultralight/60 px-2.5 py-1.5 text-sm font-semibold text-aimfox-normal shadow-sm hover:bg-aimfox-ultralight">
								Set licenses
							</button>
						</th>
					</tr>
				</thead>

				<tbody class="divide-y divide-gray-200 bg-white">
					<tr v-for="license in licenses" :key="license.account_id">
						<td class="py-2 pl-4 text-sm text-gray-500">{{ license.account_id }}</td>
						<td class="px-2 py-2 text-sm text-gray-500">{{ license.status }}</td>
						<td class="px-2 py-2 text-sm text-gray-500">{{ license.lifetime }}</td>
						<td class="px-2 py-2 text-sm text-gray-500">{{ license.expires_at }}</td>
						<td class="px-2 py-2 text-sm text-gray-500">{{ license.source }}</td>
						<td v-if="userRole !== 'maintainer'" class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
							<span class="sr-only">Edit</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<LicenseModal
		:show="openLicenseModal"
		@close-license-modal="openLicenseModal = false" @add-licenses="addLicensesToWorkspace">
	</LicenseModal>
</template>

<script>
import LicenseModal from "./components/LicenseModal.vue";
import customersService from "../../services/customers";

export default {
	emits: ["show-toast"],
	components: {
		LicenseModal
	},
	props: {
		licenses: {
			type: Array,
			required: true
		},
		chargebeeId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			userRole: localStorage.getItem("userRole"),
			openLicenseModal: false
		};
	},
	methods: {
		addLicensesToWorkspace(licenseCount) {
			this.openLicenseModal = false;

			return customersService.addLicenses(this.chargebeeId, licenseCount).then(() => {
				setTimeout(() => {
					window.location.reload();
				}, 2500);

				return this.$emit("show-toast", { type: "Success", message: "Added licenses", status: "success" });
			}).catch(() => {
				return this.$emit("show-toast", { type: "LicenseError", message: "Error adding licenses.", status: "error" });
			});
		}
	}
};
</script>
