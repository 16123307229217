<template>
	<TransitionRoot as="template">
		<Dialog as="div" class="relative z-10" @close="closeModal">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
				leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
							<div>
								<div class="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-green-100">
									<CreditCardIcon class="h-10 w-10 text-green-600" aria-hidden="true" />
								</div>
								<div class="mt-3 text-center sm:mt-5">
									<DialogTitle as="h3" class="text-base font-semibold text-gray-900">Set Licenses</DialogTitle>

									<p class="text-sm">This sets the total count of licenses for the customer to the number below.
										The chosen number won't be added on top of the current licenses!
									</p>

									<div class="mt-2">
										<div class="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
											<div class="flex flex-1 items-center justify-center">
												<div>
													<nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
														<div @click="decrementCount" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer">
															<MinusIcon class="h-5 w-5" aria-hidden="true" />
														</div>

														<p class="relative z-10 inline-flex items-center bg-gray-400/20 px-4 py-2 text-sm font-semibold text-gray-900 focus:z-20 ring-1 ring-inset ring-gray-300">{{ licenseCount }}</p>

														<div @click="incrementCount" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer">
															<PlusIcon class="h-5 w-5" aria-hidden="true" />
														</div>
													</nav>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sm:gap-3">
								<div class="w-full">
									<button type="button"
										class="w-full inline-flex justify-center rounded-md bg-aimfox-normal px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-aimfox-light"
										@click="addLicenses">
										Confirm
									</button>
								</div>

								<div class="w-full">
									<button type="button"
										class="w-full inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
										@click="closeModal" ref="cancelButtonRef">
										Cancel
									</button>
								</div>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { CreditCardIcon } from "@heroicons/vue/24/outline";
import { MinusIcon, PlusIcon } from "@heroicons/vue/20/solid";
</script>

<script>
export default {
	emits: ["close-license-modal", "add-licenses"],
	data() {
		return {
			licenseCount: 1
		};
	},
	methods: {
		addLicenses() {
			this.$emit("add-licenses", this.licenseCount);

			this.licenseCount = 1;
		},
		closeModal() {
			this.$emit("close-license-modal");

			this.licenseCount = 1;
		},
		decrementCount() {
			if (this.licenseCount === 1) {
				return;
			}

			return this.licenseCount -= 1;
		},
		incrementCount() {
			return this.licenseCount += 1;
		}
	}
};
</script>
