<template>
	<div class="flex-1 border-t border-gray-100 sm:p-14 p-8">
		<div class="border-gray-100">
			<dd class="text-sm text-gray-900">
				<ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg">
					<li v-for="(value, key) in subscription" :key="key" class="flex items-center justify-between py-3 pl-4 pr-5 text-sm leading-6">
						<div class="flex w-0 flex-1 items-center">
							<MinusSmallIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />

							<div class="ml-4 flex min-w-0 flex-1 gap-2">
								<span class="truncate font-medium">{{ key }}: {{ value }}</span>
							</div>
						</div>
					</li>
				</ul>
			</dd>
		</div>
	</div>
</template>

<script setup>
import { MinusSmallIcon } from "@heroicons/vue/20/solid";
</script>

<script>
export default {
	props: {
		subscription: {
			type: Object,
			required: true
		}
	}
};
</script>
