<template>
	<div class="border-t border-gray-100 py-4 col-span-2 px-4">
		<div v-for="campaign in campaigns" :key="campaign.id" class="lg:col-start-3 lg:row-end-1 lg:w-4/12 inline-block px-6 py-4">
			<div class="rounded-lg bg-gray-50 shadow-md ring-1 ring-gray-900/5">
				<dl class="flex flex-wrap">
					<div class="flex-auto pl-6 pt-6">
						<dt class="mt-1 text-base font-extrabold leading-6 text-gray-900">{{ campaign.name }}</dt>
						<dd class="mt-1 text-base font-semibold leading-6 text-gray-900">{{ campaign.id }}</dd>
					</div>

					<div class="flex-none self-end px-6 pt-4">
						<dt class="sr-only">Status</dt>

						<state-badge :customClass="stateBadgeClass(campaign.state)">
							<template v-slot:state>{{ campaign.state }}</template>
						</state-badge>
					</div>

					<div class="flex-auto pl-6 pt-6 w-full border-b border-gray-900/5 pb-6">
						<dt class="text-sm font-semibold leading-6 text-gray-900">Type: {{ campaign.type }}</dt>
						<dd class="text-sm font-semibold leading-6 text-gray-900">Target Count: {{ campaign.target_count }}</dd>
						<dd class="text-sm font-semibold leading-6 text-gray-900">Created At: {{ new Date(campaign.created_at).toLocaleString() }} - {{  campaign.created_at }}</dd>
					</div>

					<div class="pb-6 pt-2">
						<div v-for="metric in metrics" :key="metric.name" class="mt-2 flex w-full flex-none px-6">
							<dt class="flex-none">
								<span class="sr-only">{{ metric.label }}</span>
								<component :is="metric.icon" class="h-6 w-5 text-gray-400" aria-hidden="true" />
							</dt>

							<dd class="text-sm font-medium leading-6 text-gray-500 ml-4">
								{{ metric.label }}: {{ campaign.metrics[metric.name] }}
							</dd>
						</div>
					</div>
				</dl>
			</div>
		</div>
	</div>
</template>

<script setup>
import { EyeIcon, ChatBubbleLeftIcon, UserPlusIcon, UsersIcon, EnvelopeIcon, ArrowUturnLeftIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import StateBadge from "../../components/badges/StateBadge.vue";

export default {
	props: {
		campaigns: {
			type: Array,
			required: true
		}
	},
	components: {
		StateBadge
	},
	computed: {
		stateBadgeClass() {
			return (state) => {
				switch (state) {
				case "PAUSED":
					return "bg-yellow-50 text-yellow-700 ring-yellow-600/20 py-1 px-2";
				case "INTERRUPTED":
					return "bg-orange-50 text-orange-700 ring-orange-600/20 py-1 px-2";
				case "ACTIVE":
					return "bg-green-50 text-green-700 ring-green-600/20 py-1 px-2";
				default:
					return "bg-gray-50 text-gray-700 ring-gray-600/20 py-1 px-2";
				}
			};
		},
		metrics() {
			return [
				{ name: "sent_connections", icon: UsersIcon, label: "Sent Connections" },
				{ name: "accepted_connections", icon: UserPlusIcon, label: "Accepted Connections" },
				{ name: "sent_messages", icon: ChatBubbleLeftIcon, label: "Sent Messages" },
				{ name: "sent_inmails", icon: EnvelopeIcon, label: "Sent Inmails" },
				{ name: "replies", icon: ArrowUturnLeftIcon, label: "Replies" },
				{ name: "views", icon: EyeIcon, label: "Views" }
			];
		}
	}
};
</script>
