<template>
	<main>
		<section>
			<div class="flex items-center justify-between">
				<div class="hidden sm:flex sm:items-baseline sm:space-x-8">
					<div id="menu" class="relative inline-block text-left">
						<button type="button" @click="toggleFormVisibility"
							class="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
							<AdjustmentsVerticalIcon class="h-5 w-5 cursor-pointer text-aimfox-normal" />
						</button>

						<transition enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<div v-if="showForm" class="absolute right-0 z-10 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
								<div class="h-screen w-full fixed left-0 top-0 z-10" @click="toggleFormVisibility"></div>

								<form class="space-y-2 z-20 relative">
									<div class="flex items-center justify-between">
										<span>Sort By:</span>

										<XMarkIcon @click="toggleFormVisibility" class="cursor-pointer h-5 w-5 text-gray-300 hover:text-gray-800" />
									</div>

									<hr>

									<div class="relative">
										<div class="flex items-center py-1" v-for="(category, index) in categories" :key="index">
											<input :id="'filter-category-' + index" type="checkbox"
												class="h-4 w-4 rounded border-gray-300 text-aimfox-normal focus:ring-aimfox-light cursor-pointer"
												:checked="category.selected" @change="toggleCategory(index)">
											<label :for="'filter-category-' + index"
												class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 cursor-pointer">
												{{ category.label }}
											</label>
										</div>
									</div>

									<hr>

									<div class="flex justify-between">
										<button type="button" @click="selectAll"
											class="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-black hover:text-aimfox-normal shadow-sm hover:bg-aimfox-ultralight">
											Select All
										</button>

										<button type="button" @click="clearForm"
											class="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-black hover:text-aimfox-normal shadow-sm hover:bg-aimfox-ultralight">
											Clear
										</button>

										<button type="button" @click="emitSort"
											class="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-black hover:text-aimfox-normal shadow-sm hover:bg-aimfox-ultralight">
											Sort
										</button>
									</div>
								</form>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script setup>
import { AdjustmentsVerticalIcon, XMarkIcon } from "@heroicons/vue/24/outline";
</script>

<script>
export default {
	data() {
		return {
			showForm: false,
			categories: [
				{ label: "Sent Connections", selected: false, name: "sent_connections" },
				{ label: "Accepted Connections", selected: false, name: "accepted_connections" },
				{ label: "Views", selected: false, name: "views" },
				{ label: "Replies", selected: false, name: "replies" },
				{ label: "Sent Inmails", selected: false, name: "sent_inmails" },
				{ label: "Sent Messages", selected: false, name: "sent_messages" }
			]
		};
	},
	methods: {
		toggleFormVisibility() {
			this.showForm = !this.showForm;
		},

		toggleCategory(index) {
			this.categories[index].selected = !this.categories[index].selected;
		},

		clearForm() {
			this.categories.forEach(category => {
				category.selected = false;
			});
		},

		selectAll() {
			this.categories.forEach(category => {
				category.selected = true;
			});
		},

		emitSort() {
			const filters = this.categories.filter(category => category.selected);

			this.$emit("sort-metrics", filters);

			this.toggleFormVisibility();
		}
	}
};
</script>
