<template>
     <div class="lg:ml-4 -ml-2 flex items-center">
        <Menu as="div" class="relative ml-4 flex-shrink-0">
            <MenuButton class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-aimfox-normal focus:ring-offset-2">
                <span class="absolute -inset-1.5" />
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" :src=userImage alt="User Image" />
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                    <MenuItem v-slot="{ active }">
                        <div @click="signOut" :class="[active ? 'bg-gray-100' : '', 'flex justify-between items-center px-4 py-2 text-sm text-gray-700']">
                            <p class="flex-grow">Sign out</p>
                            <font-awesome-icon icon="arrow-right-from-bracket" />
                        </div>
                    </MenuItem>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
</script>

<script>
import axios from "axios";
import redirect from "../../services/redirect";
import config from "../../services/config";

export default {
	methods: {
		signOut: async function () {
			return axios.post(config.get("HOST") + "/api/v1/logout", {}, {
				headers: {
					"Authorization": "Bearer " + localStorage.getItem("auth")
				}
			}).then(() => {
				localStorage.removeItem("auth");

				return redirect.getUrl().then(url => {
					window.location.href = url;
				});
			});
		}
	},
	data() {
		return {
			userImage: localStorage.getItem("userImage")
		};
	}
};
</script>
