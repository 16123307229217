<template>
	<form class="bg-white shadow-xl rounded-xl md:col-span-2">
		<div class="px-4 py-6 sm:p-8">
			<div class="wrapper w-full h-full flex items-center pb-6">
				<h2 class="text-4xl font-semibold leading-7 text-gray-900 mr-auto">
					<slot name="label"></slot>
				</h2>

				<div class="h-12 w-12 text-aimfox-normal">
					<slot name="icon"></slot>
				</div>
			</div>

			<div class="grid max-w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
				<div class="col-span-full">
					<Listbox as="div" v-model="selected">
						<ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Select a prompt</ListboxLabel>

						<div class="relative mt-2">
							<ListboxButton class="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-aimfox-normal sm:text-sm sm:leading-6">
								<span class="block truncate">{{ selected.name }}</span>
								<span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
									<ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
								</span>
							</ListboxButton>

							<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
								<ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									<ListboxOption as="template" v-for="prompt in existingPrompts" :key="prompt.id" :value="prompt"
										v-slot="{ active, selected }">
										<li :class="[active ? 'bg-aimfox-normal text-white' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9']">
											<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
												{{ prompt.name }}
											</span>

											<span v-if="prompt.default"
												:class="[active ? 'text-white' : 'text-aimfox-normal', 'absolute inset-y-0 right-0 flex items-center pr-4']">
												<BookmarkIcon class="h-5 w-5" aria-hidden="true" />
											</span>

											<span v-if="prompt.id === 'new-prompt'"
												:class="[active ? 'text-white' : 'text-aimfox-normal', 'absolute inset-y-0 right-0 flex items-center pr-4']">
												<PlusIcon class="h-5 w-5" aria-hidden="true" />
											</span>
										</li>
									</ListboxOption>
								</ListboxOptions>
							</transition>
						</div>
					</Listbox>
				</div>

				<div class="col-span-full">
					<label for="prompt-name" class="block text-sm font-medium leading-6 text-gray-900">Prompt name</label>

					<div class="mt-2">
						<input type="text" name="prompt-name" v-model="updatedSelected.name" placeholder="Enter prompt name"
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aimfox-normal sm:text-sm sm:leading-6" />
					</div>
				</div>

				<div class="col-span-full">
					<label for="about" class="block text-sm font-medium leading-6 text-gray-900">Prompt</label>

					<div class="mt-2">
						<textarea id="about" name="about" rows="3" v-model="updatedSelected.prompt" placeholder="Enter prompt"
							class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aimfox-normal sm:text-sm sm:leading-6" />
					</div>

					<p class="mt-3 text-sm leading-6 text-gray-600">Write a prompt for AI to generate.</p>
				</div>
			</div>
		</div>

		<div class="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
			<div class="info-wrapper">
				<div class="flex items-center">
					<div class="has-tooltip">
						<span class='tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 -mt-8 -ml-3 text-sm'>
							All AI generated <slot name="type"></slot> will use the default prompt.
						</span>

						<InformationCircleIcon class="h-5 w-5 text-gray-400 cursor-pointer"></InformationCircleIcon>
					</div>

					<p v-if="selected.default" class="text-sm leading-6 text-gray-400 ml-1">Default prompt.</p>
					<p v-else class="text-sm leading-6 text-gray-400 ml-1">Not default prompt.</p>
				</div>
			</div>

			<div class="button-wrapper flex gap-x-6">
				<div class="default-button-wrapper flex align-middle justify-center">
					<button type="reset" value="reset" :disabled="selected.id === 'new-prompt' || selected.default"
						class="text-sm font-semibold leading-6 text-gray-900 hover:text-gray-500 disabled:opacity-10 disabled:hover:text-gray-900 hidden sm:block"
						@click="openDefaultModal = true">
						Set As Default
					</button>
				</div>

				<button type="reset" value="reset" :disabled="selected.id === 'new-prompt' || selected.default"
					class="text-sm font-semibold leading-6 text-gray-900 hover:text-gray-500 disabled:opacity-10 disabled:hover:text-gray-900"
					@click="openDeleteModal = true">
					Delete
				</button>

				<button type="reset" value="reset" :disabled="updatedSelected.prompt == null || updatedSelected.prompt == ''"
					class="text-sm font-semibold leading-6 text-gray-900 hover:text-gray-500 disabled:opacity-10 disabled:hover:text-gray-900"
					@click="updatedSelected.prompt = null">
					Clear
				</button>

				<button type="button" @click="savePrompt" :disabled="loading"
					class="rounded-md bg-aimfox-normal px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-aimfox-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aimfox-normal disabled:opacity-10">
					Save
				</button>
			</div>
		</div>
	</form>

	<DeleteModal
		:show="openDeleteModal" :promptName="selected.name"
		@close-delete-modal="openDeleteModal = false" @delete-prompt="deletePrompt">
	</DeleteModal>

	<DefaultModal
		:show="openDefaultModal" :promptName="selected.name"
		@close-default-modal="openDefaultModal = false" @default-prompt="setAsDefault">
	</DefaultModal>
</template>

<script setup>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { BookmarkIcon, ChevronUpDownIcon, PlusIcon } from "@heroicons/vue/20/solid";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
</script>

<script>
import promptService from "../../services/prompts";
import DeleteModal from "./components/DeleteModal.vue";
import DefaultModal from "./components/DefaultModal.vue";

export default {
	emits: ["show-toast"],
	components: {
		DeleteModal,
		DefaultModal
	},
	props: {
		prompts: {
			type: Array,
			required: true
		},

		defaultPromptId: {
			type: String,
			required: true
		},

		category: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			openDeleteModal: false,
			openDefaultModal: false,
			existingPrompts: [],
			loading: false,
			selected: null,
			updatedSelected: null
		};
	},
	watch: {
		selected: {
			handler(newSelected) {
				this.updatedSelected = Object.assign({}, newSelected);
			},
			immediate: true
		},

		prompts: {
			handler(newPrompts) {
				this.existingPrompts = newPrompts.slice();

				if (!this.existingPrompts.find(prompt => prompt.id === "new-prompt")) {
					this.existingPrompts.unshift({
						id: "new-prompt",
						type: this.category,
						prompt: "",
						name: "Create a new prompt"
					});
				}

				const currentDefault = this.existingPrompts.find(prompt => prompt.default === true);

				this.selected = currentDefault || this.existingPrompts[0];
			},
			immediate: true
		}
	},
	created() {
		const currentDefault = this.existingPrompts.find(prompt => prompt.default === true);
		this.selected = currentDefault || this.existingPrompts[0];
		this.updatedSelected = currentDefault || this.existingPrompts[0];
	},
	methods: {
		deletePrompt() {
			this.openDeleteModal = false;

			return promptService.delete(this.selected.id).then(() => {
				this.existingPrompts = this.existingPrompts.filter(prompt => prompt.id !== this.selected.id);
				this.selected = this.existingPrompts[0];

				return this.$emit("show-toast", { type: "Success", message: "Prompt deleted successfully", status: "success" });
			}).catch(() => {
				return this.$emit("show-toast", { type: "PromptError", message: "Error deleting prompt.", status: "error" });
			});
		},

		setAsDefault() {
			this.openDefaultModal = false;

			return promptService.updateDefault(this.defaultPromptId, {
				"type": this.category,
				"prompt_id": this.selected.id
			}).then(() => {
				const currentDefault = this.existingPrompts.find(prompt => prompt.default === true);
				currentDefault.default = false;

				const newDefault = this.existingPrompts.find(prompt => prompt.id === this.selected.id);
				newDefault.default = true;

				return this.$emit("show-toast", { type: "Success", message: "Default prompt updated successfully", status: "success" });
			}).catch(() => {
				return this.$emit("show-toast", { type: "PromptError", message: "Error updating prompt.", status: "error" });
			});
		},

		savePrompt() {
			this.loading = true;
			const selectedId = this.selected.id;

			if (!this.updatedSelected.name || !this.updatedSelected.prompt) {
				this.loading = false;

				return this.$emit("show-toast", { type: "ValidationError", message: "One or more invalid values.", status: "warning" });
			}

			const promptObject = {
				type: this.category,
				prompt: this.updatedSelected.prompt,
				name: this.updatedSelected.name
			};

			if (selectedId === "new-prompt") {
				return promptService.create(promptObject).then(response => {
					this.loading = false;

					this.existingPrompts.push({ ...promptObject, id: response.data.prompt.id, default: false });
					this.selected = this.existingPrompts[this.existingPrompts.length - 1];

					return this.$emit("show-toast", { type: "Success", message: "Prompt created successfully", status: "success" });
				}).catch(() => {
					this.loading = false;

					return this.$emit("show-toast", { type: "PromptError", message: "Error creating prompt.", status: "error" });
				});
			}

			return promptService.update(selectedId, promptObject).then(() => {
				this.loading = false;

				const updatedPrompt = this.existingPrompts.find(prompt => prompt.id === selectedId);

				updatedPrompt.prompt = promptObject.prompt;
				updatedPrompt.name = promptObject.name;

				return this.$emit("show-toast", { type: "Success", message: "Prompt updated successfully", status: "success" });
			}).catch(() => {
				this.loading = false;

				return this.$emit("show-toast", { type: "PromptError", message: "Error updating prompt.", status: "error" });
			});
		}
	}
};
</script>

<style>
.tooltip {
	@apply invisible absolute;
}

.has-tooltip:hover .tooltip {
	@apply visible z-50;
}

@media only screen and (max-width: 1400px) and (min-width: 770px)  {
	.info-wrapper {
		display: none;
	}
}

@media only screen and (max-width: 1050px) and (min-width: 770px)  {
	.default-button-wrapper {
		display: none;
	}
}
</style>
