<template>
    <ul role="list" class="grid grid-cols-1 sm:grid-cols-4 gap-6 sm:p-14 p-8">
        <li v-for="account in accounts" :key="account.email" class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-lg border">
            <div class="flex flex-1 flex-col p-6">
                <div class="relative w-32 h-32 mx-auto">
                    <img class="h-32 w-32 flex-shrink-0 rounded-full" :src="account.picture_url" alt="" />

                    <div class="absolute bottom-0 right-0 group">
                        <span class="whitespace-nowrap absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 hidden group-hover:block rounded shadow-lg p-1 bg-gray-100 text-gray-500 text-sm">
                            Account has a license
                        </span>

                        <CreditCardIcon v-if="account.license" class="p-1 h-8 w-8 text-green-500 bg-white border border-gray-100 rounded-full"></CreditCardIcon>
                    </div>
                </div>

				<p class="text-sm text-gray-400 pt-2">{{ account.id }}</p>
                <h3 class="mt-2 text-sm font-medium text-gray-900">{{ account.full_name }}</h3>

                <dl class="mt-1 flex flex-grow flex-col justify-between">
                    <dd class="text-sm text-gray-500">{{ account.occupation }}</dd>

                    <dd class="mt-2">
                        <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
							{{ account.state }}
						</span>
                    </dd>
                </dl>
            </div>
        </li>
    </ul>
</template>

<script setup>
import { CreditCardIcon } from "@heroicons/vue/24/outline";
</script>

<script>
export default {
	props: {
		accounts: {
			type: Array,
			required: true
		}
	}
};
</script>

<style>
.tooltip {
	@apply invisible absolute;
}

.has-tooltip:hover .tooltip {
	@apply visible z-50;
}
</style>
