<template>
	<div class="pb-10 px-4">
		<dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-6">
			<MetricsComponent v-for="item in displayData" :key="item.title" :titleValue="item.title">
				<template v-slot:icon>
					<component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
				</template>

				<template v-slot:title>
					{{ item.title }}
				</template>

				<template v-slot:content>
					{{ item.value }}
				</template>
			</MetricsComponent>
		</dl>
	</div>

	<MetricsChart :rawMetrics="rawMetrics" class="px-14"></MetricsChart>
</template>

<script setup>
import { EyeIcon, ChatBubbleLeftIcon, UserPlusIcon, UsersIcon, EnvelopeIcon, ArrowUturnLeftIcon } from "@heroicons/vue/24/outline";
import MetricsChart from "../../components/charts/MetricsChart.vue";
import MetricsComponent from "./components/MetricsComponent.vue";
</script>

<script>
export default {
	components: {
		MetricsChart,
		MetricsComponent
	},
	created() {
		this.accountMetrics = this.rawMetrics.metrics.reduce((acc, bucket) => {
			acc.sent_connections += bucket.sent_connections;
			acc.accepted_connections += bucket.accepted_connections;
			acc.sent_messages += bucket.sent_messages;
			acc.sent_inmails += bucket.sent_inmails;
			acc.replies += bucket.replies;
			acc.views += bucket.views;

			return acc;
		}, this.accountMetrics);
	},
	methods: {
		setDisplayData() {
			this.displayData = [
				{
					title: "Sent Connections",
					icon: UsersIcon,
					value: this.accountMetrics.sent_connections
				},
				{
					title: "Accepted Connections",
					icon: UserPlusIcon,
					value: this.accountMetrics.accepted_connections
				},
				{
					title: "Sent Messages",
					icon: ChatBubbleLeftIcon,
					value: this.accountMetrics.sent_messages
				},
				{
					title: "Sent Inmails",
					icon: EnvelopeIcon,
					value: this.accountMetrics.sent_inmails
				},
				{
					title: "Replies",
					icon: ArrowUturnLeftIcon,
					value: this.accountMetrics.replies
				},
				{
					title: "Views",
					icon: EyeIcon,
					value: this.accountMetrics.views
				}
			];
		}
	},
	mounted() {
		this.setDisplayData();
	},
	data() {
		return {
			accountMetrics: {
				sent_connections: 0,
				accepted_connections: 0,
				sent_messages: 0,
				sent_inmails: 0,
				replies: 0,
				views: 0
			},
			metricsData: [],
			displayData: []
		};
	},
	props: {
		rawMetrics: {
			type: Object,
			required: true
		}
	},
	watch: {
		rawMetrics(newMetrics) {
			this.accountMetrics = newMetrics.metrics.reduce((acc, bucket) => {
				acc.sent_connections += bucket.sent_connections;
				acc.accepted_connections += bucket.accepted_connections;
				acc.sent_messages += bucket.sent_messages;
				acc.sent_inmails += bucket.sent_inmails;
				acc.replies += bucket.replies;
				acc.views += bucket.views;

				return acc;
			}, {
				sent_connections: 0,
				accepted_connections: 0,
				sent_messages: 0,
				sent_inmails: 0,
				replies: 0,
				views: 0
			});
		},

		accountMetrics() {
			this.setDisplayData();
		}
	}
};
</script>
