const axios = require("axios");
const config = require("../config");

module.exports = {
	countActive: () => {
		return axios.get(config.get("HOST") + "/api/v1/campaigns/active", {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.campaigns;
		});
	}
};
