<template>
	<TransitionRoot as="template">
		<Dialog as="div" class="relative z-10" @close="closeModal">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
				leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>

			<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
							<div>
								<div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-aimfox-ultralight">
									<PencilSquareIcon class="h-6 w-6 text-aimfox-normal" aria-hidden="true" />
								</div>

								<div class="mt-3 text-center sm:mt-3">
									<DialogTitle as="h3" class="text-xl font-semibold leading-6 text-gray-900">Edit Customer Email</DialogTitle>

									<div class="relative">
										<div class="mt-2">
											<p class="text-sm text-gray-500 pb-2 text-left pl-1 font-bold">Email address</p>
										</div>

										<input
											id="email"
											name="email"
											class="block w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aimfox-normal sm:text-sm sm:leading-6"
											placeholder="Enter text"
											type="email"
											v-model="emailText" />

										<p class="text-xs text-red-500 mt-0.5 text-left ml-1" v-show="emailError">The email address provided is invalid. Please enter a valid email address</p>
									</div>

								</div>
							</div>

							<div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
								<button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 sm:mt-0 sm:w-auto"
									@click="closeModal" ref="cancelButtonRef">
									Cancel
								</button>

								<button type="button" class="inline-flex w-full justify-center rounded-md bg-aimfox-normal px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-aimfox-light sm:ml-3 sm:w-auto disabled:opacity-10"
									@click="submitEdit" :disabled="emailError || !emailText">
									Confirm
								</button>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { PencilSquareIcon } from "@heroicons/vue/24/outline";
</script>

<script>
export default {
	emits: ["submit-edit", "close-edit-modal"],
	data() {
		return {
			emailText: null,
			emailError: false
		};
	},
	methods: {
		submitEdit() {
			this.$emit("submit-edit", this.emailText);
		},

		closeModal() {
			this.$emit("close-edit-modal");
		}
	},
	watch: {
		emailText(newText) {
			if (newText === "") {
				this.emailError = false;
			}

			if (newText) {
				const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

				return this.emailError = !emailRegex.test(newText);
			}
		}
	}
};
</script>
