<template>
	<div class="flex flex-row">
		<div class="flex-1 px-4 py-0">
			<InfoComponent v-for="item in accountData" :key="item.label" @copy-slot="copyHandler(item)">
				<template v-slot:label>
					{{ item.label }}
				</template>

				<template v-slot:value>
					{{ item.value }}
				</template>
			</InfoComponent>
		</div>
	</div>
</template>

<script>
import InfoComponent from "./components/InfoComponent.vue";
import copy from "copy-to-clipboard";

export default {
	emits: ["show-toast"],
	props: {
		account: {
			type: Object,
			required: true
		}
	},
	components: {
		InfoComponent
	},
	data() {
		return {
			accountData: [
				{ label: "PK", value: this.account.id },
				{ label: "Full Name", value: this.account.full_name },
				{ label: "Email Address", value: this.account.email },
				{ label: "Public Identifier", value: this.account.public_identifier },
				{ label: "Account State", value: this.account.state },
				{ label: "Customer ID", value: this.account.customer_id },
				{ label: "Customer Email", value: this.account.customer_email },
				{ label: "Chargebee ID", value: this.account.chargebee_id }
			]
		};
	},
	methods: {
		copyHandler(key) {
			if (!key.value) {
				return this.$emit("show-toast", { type: "Error", message: `Error copying "${key.label}"`, status: "error" });
			}

			copy(key.value);

			return this.$emit("show-toast", { type: "Success", message: `Copied "${key.label}"`, status: "success" });
		}
	}
};
</script>
