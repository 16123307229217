<template>
    <div class="ml-auto pr-12 grid gap-3 sm:inline-block sm:space-x-3 sm:grid-cols-1 sm:gap-0 sm:space-y-0 grid-cols-2">
        <a :href="url" target="_blank" class="cursor-pointer group relative inline-flex items-center rounded-lg bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset hover:ring-0 ring-gray-300/20 hover:bg-slate-200/20 hover:text-white focus:z-10">
            <font-awesome-icon icon="user-secret" class="mr-3 h-5 w-5 text-white" aria-hidden="true" />
            Impersonate
        </a>

        <button type="button" @click="copyUrl" class="cursor-pointer group relative inline-flex items-center rounded-lg bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset hover:ring-0 ring-gray-300/20 hover:bg-slate-200/20 hover:text-white focus:z-10">
            <DocumentDuplicateIcon class="mr-3 h-5 w-5 text-white" aria-hidden="true" />
            Copy URL
        </button>

        <button type="button" @click="copyToken" class="cursor-pointer group relative inline-flex items-center rounded-lg bg-gray-800 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset hover:ring-0 ring-gray-300/20 hover:bg-slate-200/20 hover:text-white focus:z-10 whitespace-nowrap">
            <KeyIcon class="mr-3 h-5 w-5 text-white" aria-hidden="true" />
            Copy Token
        </button>
    </div>
</template>

<script setup>
import { DocumentDuplicateIcon, KeyIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import impersonateService from "../../../services/impersonate";
import copy from "copy-to-clipboard";

export default {
	emits: ["show-toast"],
	props: {
		customerId: {
			type: String,
			required: true
		},
		workspaceId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			url: null,
			token: null
		};
	},
	created() {
		this.getImpersonateUrl();
	},
	methods: {
		getImpersonateUrl: async function () {
			return impersonateService.getUrl(this.customerId)
				.then(response => {
					const splitUrl = response.split("#");

					this.token = splitUrl[1];
					this.url = `${splitUrl[0]}workspaces/${this.workspaceId}/home#${splitUrl[1]}`;
				}).catch(() => {
					return this.$emit("show-toast", { type: "UrlError", message: "Error getting url.", status: "error" });
				});
		},
		copyUrl() {
			if (!this.url) {
				return this.$emit("show-toast", { type: "UrlError", message: "Error copying url.", status: "error" });
			}

			copy(this.url);

			return this.$emit("show-toast", { type: "Success", message: "Successfully copied url.", status: "success" });
		},
		copyToken() {
			if (!this.url) {
				return this.$emit("show-toast", { type: "TokenError", message: "Error copying token.", status: "error" });
			}

			copy(this.token);

			return this.$emit("show-toast", { type: "Success", message: "Successfully copied token.", status: "success" });
		}
	}
};
</script>
