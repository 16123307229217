<template>
	<header class="bg-gray-100 relative shadow-inner">
		<div class="py-6 px-8">
			<div class="flex flex-col sm:flex-row items-center justify-between gap-y-4 sm:gap-x-8">
				<div class="flex items-center gap-x-6">
					<UserGroupIcon class="h-20 w-20 flex-none text-aimfox-normal" />

					<h1>
						<div class="mt-1 text-3xl font-semibold text-gray-900">Customers</div>
						<div class="text-m leading-6 text-gray-500">
							A detailed list showing relevant information about aimfox customers.
						</div>
					</h1>
				</div>

				<SwitchComponent
					class="w-full sm:w-auto"
					@list-ltd-customers="filterLtdCustomers"
					@list-appsumo-customers="filterAppsumoCustomers"
					@list-customers="filterCustomers">
				</SwitchComponent>
			</div>
		</div>
	</header>

	<TableList
		class="hidden md:block"
		:tableCustomers="allCustomers"
		:sortedBy="sortedBy"
		:reversed="reversed"
		@search-customers="searchCustomers"
		@sort-customers="sortCustomers"
		@copy-url="copyUrl"
		@copy-token="copyToken"
		@add-licenses="showLicenseModal"
		@edit-customer="editCustomer">
	</TableList>

	<StackedList
		class="md:hidden"
		:stackedCustomers="allCustomers"
		@search-customers="searchCustomers"
		@copy-url="copyUrl"
		@add-licenses="showLicenseModal"
		@copy-token="copyToken"
		@edit-customer="editCustomer">
	</StackedList>

	<EditModal
		:show="openEditModal"
		@close-edit-modal="openEditModal = false" @submit-edit="submitEdit">
	</EditModal>

	<LicenseModal
		:show="openLicenseModal"
		@close-license-modal="openLicenseModal = false" @add-licenses="addLicensesToCustomer">
	</LicenseModal>
</template>

<script setup>
import { UserGroupIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import copy from "copy-to-clipboard";
import { useToast } from "vue-toastification";
import DefaultToast from "../../components/toasts/DefaultToast.vue";
import SwitchComponent from "./components/SwitchComponent.vue";
import EditModal from "./components/EditModal.vue";
import StackedList from "./components/StackedList.vue";
import TableList from "./components/TableList.vue";
import LicenseModal from "./components/LicenseModal.vue";
import customersService from "../../services/customers";

export default {
	emits: ["list-active-customers", "list-customers", "search-customers", "sort-customers", "edit-customer", "list-appsumo-customers"],
	props: {
		allCustomers: {
			type: Array,
			required: true
		},
		sortedBy: {
			type: String
		},
		reversed: {
			type: Boolean
		}
	},
	components: {
		StackedList,
		TableList,
		SwitchComponent,
		LicenseModal
	},
	data() {
		return {
			openEditModal: false,
			toast: useToast({ timeout: 1500 }),
			chargebeeId: null,
			openLicenseModal: false
		};
	},
	methods: {
		showLicenseModal(chargebee_id) {
			this.openLicenseModal = true;

			this.chargebeeId = chargebee_id;
		},
		addLicensesToCustomer(licenseCount) {
			this.openLicenseModal = false;

			return customersService.addLicenses(this.chargebeeId, licenseCount).then(() => {
				setTimeout(() => {
					window.location.reload();
				}, 2500);

				return this.showSuccess({ type: "Success", message: "Added licenses" });
			}).catch(() => {
				return this.showError({ type: "LicenseError", message: "Error adding licenses."});
			});
		},

		editCustomer(chargebeeId) {
			this.openEditModal = true;

			this.chargebeeId = chargebeeId;
		},

		submitEdit(editText) {
			this.openEditModal = false;

			const customerObject = {
				chargebee_id: this.chargebeeId,
				email: editText
			};

			this.$emit("edit-customer", customerObject);
		},

		searchCustomers(searchData) {
			this.$emit("search-customers", searchData);
		},

		sortCustomers(sortData) {
			this.$emit("sort-customers", sortData);
		},

		filterLtdCustomers() {
			this.$emit("list-ltd-customers");
		},

		filterAppsumoCustomers() {
			this.$emit("list-appsumo-customers");
		},

		filterCustomers() {
			this.$emit("list-customers");
		},

		copyUrl(url) {
			copy(url);

			this.showSuccess({ type: "Success", message: "Successfully copied url." });
		},

		copyToken(url) {
			const token = url.split("#");

			copy(token[1]);

			this.showSuccess({ type: "Success", message: "Successfully copied token." });
		},

		showSuccess(errorObject) {
			this.toast.success({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		showError(errorObject) {
			this.toast.warning({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		}
	}
};
</script>
