<template>
    <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow sm:px-6 sm:pt-6">
        <dt>
            <div :class="slotClass">
                <slot name="icon"></slot>
            </div>
            <span class="ml-16 truncate text-sm font-medium text-gray-500">
                <slot name="title"></slot>
            </span>
        </dt>

        <dd class="ml-16 flex items-baseline">
            <span class="text-2xl font-semibold text-gray-900">
                <slot name="content"></slot>
            </span>
        </dd>
    </div>
</template>

<script>
export default {
	props: {
		titleValue: {
			type: String,
			required: true
		}
	},
	computed: {
		slotClass() {
			switch (this.titleValue) {
			case "Sent Connections":
				return "absolute rounded-md p-3 bg-blue-500";
			case "Accepted Connections":
				return "absolute rounded-md p-3 bg-emerald-400";
			case "Sent Messages":
				return "absolute rounded-md p-3 bg-cyan-400";
			case "Sent Inmails":
				return "absolute rounded-md p-3 bg-violet-500";
			case "Replies":
				return "absolute rounded-md p-3 bg-orange-400";
			case "Views":
				return "absolute rounded-md p-3 bg-red-500";
			default:
				return "absolute rounded-md p-3 bg-gray-500";
			}
		}
	}
};
</script>
