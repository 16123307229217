<template>
	<Listbox as="div" v-model="selected" v-slot="{ open }">
		<div class="relative mt-2">
			<ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 px-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-aimfox-normal sm:text-sm sm:leading-6 flex items-center justify-between">
				<span class="block truncate">{{ selected.name }}</span>

				<ChevronDownIcon :class="['text-sm transition-transform ease-linear h-5 w-5 text-gray-700 origin-center', open ? '[transform:rotateX(180deg)]' : null]"/>
			</ListboxButton>

			<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
				<ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
					<ListboxOption as="template" v-for="tab in propTabs" :key="tab.name" :value="tab" v-slot="{ active, selected }" @click="changeTab(tab)">
						<li v-if="userRole !== 'developer' && tab.name !== 'Limits'" :class="[active ? 'bg-aimfox-normal text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
							<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ tab.name }}</span>

							<span v-if="selected" :class="[active ? 'text-white' : 'text-aimfox-normal', 'absolute inset-y-0 right-0 flex items-center pr-4']">
								<CheckIcon class="h-5 w-5" aria-hidden="true" />
							</span>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</transition>
		</div>
	</Listbox>
</template>

<script setup>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";
</script>

<script>
export default {
	emits: ["change-tab"],
	props: {
		propTabs: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			selected: null,
			userRole: localStorage.getItem("userRole")
		};
	},
	created() {
		this.selected = this.propTabs[0];
	},
	methods: {
		changeTab(newTab) {
			this.selected = newTab;

			this.$emit("change-tab", newTab);
		}
	}
};
</script>