const axios = require("axios");
const config = require("../config");

module.exports = {
	list: () => {
		return axios.get(`${config.get("HOST")}/api/v1/prompts`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.prompts;
		});
	},

	create: (promptObject) => {
		return axios.post(`${config.get("HOST")}/api/v1/prompts`, {
			data: promptObject
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response;
		});
	},

	update: (promptId, promptObject) => {
		return axios.patch(`${config.get("HOST")}/api/v1/prompts/${promptId}`, {
			data: promptObject
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response;
		});
	},

	delete: (promptId) => {
		return axios.delete(`${config.get("HOST")}/api/v1/prompts/${promptId}`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response;
		});
	},

	listDefaults: () => {
		return axios.get(`${config.get("HOST")}/api/v1/prompts/defaults`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.prompts;
		});
	},

	updateDefault: (promptId, promptObject) => {
		return axios.patch(`${config.get("HOST")}/api/v1/prompts/defaults/${promptId}`, {
			data: promptObject
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response;
		});
	}
};
