<template>
	<div class="space-y-10 divide-gray-900/10 sm:px-14 px-6 py-12 bg-gray-100 shadow-inner h-screen">
		<div class="px-4 sm:px-0">
			<h2 class="text-4xl font-semibold leading-7 text-gray-900">AI Prompts</h2>
			<p class="mt-4 text-base leading-6 text-gray-600">
				Create new AI prompts or modify existing ones</p>
		</div>

		<div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-6">
			<template v-for="(component, index) in promptComponents" :key="index">
				<PromptsComponent
					:prompts="component.prompts"
					:default-prompt-id="component.defaultPromptId"
					:category="component.category"
					@show-toast="handleToast">

					<template v-slot:label>{{ component.label }}</template>
					<template v-slot:type>{{ component.type }}</template>
					<template v-slot:icon><component :is="component.icon" /></template>
				</PromptsComponent>
			</template>
		</div>
	</div>
</template>

<script setup>
import { DocumentIcon, ChatBubbleLeftEllipsisIcon, EnvelopeIcon } from "@heroicons/vue/24/solid";
</script>

<script>
import { useToast } from "vue-toastification";
import DefaultToast from "../components/toasts/DefaultToast.vue";
import PromptsComponent from "./prompts/PromptsComponent.vue";
import promptService from "../services/prompts";

export default {
	created() {
		return Promise.all([promptService.list(), promptService.listDefaults()])
			.then(([allPrompts, defaultPrompts]) => {
				const defaultPromptsByType = defaultPrompts.reduce((acc, prompt) => {
					acc[prompt.type] = prompt;

					return acc;
				}, {});

				const markDefault = (prompts, type) => {
					const defaultPrompt = defaultPromptsByType[type];

					if (defaultPrompt) {
						const index = prompts.findIndex(element => element.id === defaultPrompt.prompt_id);

						if (index !== -1) {
							prompts[index].default = true;

							return defaultPrompt.id;
						}
					}

					return "";
				};

				const { notes, messages, inmails } = allPrompts;

				this.promptComponents = [
					{
						prompts: notes,
						defaultPromptId: markDefault(notes, "note"),
						label: "Notes",
						type: "notes",
						category: "note",
						icon: DocumentIcon
					},
					{
						prompts: messages,
						defaultPromptId: markDefault(messages, "message"),
						label: "Messages",
						type: "messages",
						category: "message",
						icon: ChatBubbleLeftEllipsisIcon
					},
					{
						prompts: inmails,
						defaultPromptId: markDefault(inmails, "inmail"),
						label: "Inmails",
						type: "inmails",
						category: "inmail",
						icon: EnvelopeIcon
					}
				];
			}).catch(() => {
				this.showError({ type: "PromptError", message: "Error getting prompts." });
			});
	},
	components: {
		PromptsComponent
	},
	data() {
		return {
			toast: useToast({ timeout: 2000 }),
			promptComponents: []
		};
	},
	methods: {
		showError(errorObject) {
			this.toast.error({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		showWarning(errorObject) {
			this.toast.warning({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		showSuccess(errorObject) {
			this.toast.success({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		handleToast(data) {
			if (data.status === "error") {
				this.showError(data);
			} else if (data.status === "warning") {
				this.showWarning(data);
			} else {
				this.showSuccess(data);
			}
		}
	}
};
</script>
