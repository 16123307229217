<template>
	<span :class="computedClasses">
		<slot name="state"></slot>
	</span>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ""
		},

		customClass: {
			type: String,
			default: ""
		}
	},
	computed: {
		computedClasses() {
			return [
				"inline-flex",
				"items-center",
				"rounded-md",
				"text-xs",
				"font-medium",
				"ring-1",
				"ring-inset",
				this.customClass
			];
		}
	}
};
</script>
