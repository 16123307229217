<template>
	<CustomersComponent
		:all-customers="getFiltered(currentPage, pageSize)"
		:sorted-by="sortedBy" :reversed="reversed"
		@search-customers="searchCustomers"
		@list-ltd-customers="listLtdCustomers"
		@list-appsumo-customers="listAppsumoCustomers"
		@list-customers="listCustomers"
		@sort-customers="sortCustomers"
		@edit-customer="editCustomer">
	</CustomersComponent>
	<Footer
		:length="filteredCustomers.length"
		:pageSize="pageSize"
		:pagesLength="getPagesLength(filteredCustomers.length, pageSize)"
		@change-page="changePage" v-bind="$attrs">
	</Footer>
</template>

<script>
import CustomersComponent from "./customers/CustomersComponent.vue";
import Footer from "../components/footer/Footer.vue";
import customers from "../services/customers";
import { useToast } from "vue-toastification";
import DefaultToast from "../components/toasts/DefaultToast.vue";

export default {
	components: {
		CustomersComponent,
		Footer
	},
	created() {
		this.listCustomers();
	},
	data() {
		return {
			pageSize: 20,
			currentPage: 1,
			enabled: false,
			copied: false,
			allCustomers: [],
			filteredCustomers: [],
			sortedBy: null,
			reversed: false,
			toast: useToast({ timeout: 2000 })
		};
	},
	methods: {
		editCustomer(customerObject) {
			const { chargebee_id, email } = customerObject;

			customers.updateCustomer(chargebee_id, { email }).then(() => {
				this.showSuccess({ type: "Success", message: "Successfully updated customer." });

				setTimeout(() => {
					window.location.reload();
				}, 2500);
			}).catch(error => {
				this.showError(error.response.data.error);
			});
		},

		sortCustomers(data) {
			const isNumber = val => typeof val === "number";

			if (this.sortedBy === data) {
				this.reversed = !this.reversed;
				this.filteredCustomers.reverse();
			} else {
				this.sortedBy = data;
				this.reversed = false;

				this.filteredCustomers.sort((a, b) => {
					const x = isNumber(a[data]) ? a[data] : String(a[data]).toLowerCase();
					const y = isNumber(b[data]) ? b[data] : String(b[data]).toLowerCase();

					return x < y ? -1 : x > y ? 1 : 0;
				});
			}
		},

		searchCustomers(searchData) {
			this.currentPage = 1;

			if (searchData === null) {
				this.filteredCustomers = this.allCustomers;
			} else {
				this.filteredCustomers = this.allCustomers.filter(customer => {
					return customer.full_name.toLowerCase().includes(searchData.toLowerCase()) ||
						customer.email.toLowerCase().includes(searchData.toLowerCase()) ||
						customer.chargebee_id === searchData.trim() ||
						customer.id === searchData.trim();
				});
			}
		},

		listAppsumoCustomers() {
			return this.filteredCustomers = this.allCustomers.filter(customer => customer.promotions)
				.filter(customer => customer.promotions[0].type === "appsumo");
		},

		listLtdCustomers() {
			return this.filteredCustomers = this.allCustomers.filter(customer => customer.promotions)
				.filter(customer => customer.promotions[0].type === "ltd");
		},

		listCustomers() {
			if (this.allCustomers.length > 0) {
				return this.filteredCustomers = this.allCustomers;
			}

			customers.list().then(response => {
				this.allCustomers = response;
				this.filteredCustomers = response;

				this.sortCustomers("created_at");
				this.sortCustomers("created_at");
			}).catch(error => {
				this.showError(error.response.data.error);
			});
		},

		showError(errorObject) {
			this.toast.warning({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		},

		showSuccess(toastObject) {
			this.toast.success({
				component: DefaultToast,
				props: {
					title: `${toastObject.type}!` || "UnknownError!",
					body: toastObject.message || "An error has occurred."
				}
			});
		},

		getPagesLength(customersLength, pageSize) {
			return Math.ceil(customersLength / pageSize);
		},

		changePage(page) {
			this.currentPage = page;
		},

		getFiltered(currentPage, pageSize) {
			return this.filteredCustomers.slice((currentPage - 1) * pageSize, currentPage * pageSize);
		}
	}
};
</script>
