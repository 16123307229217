<template>
    <Menu as="div" class="flex flex-1 items-center px-2 ml-6 justify-end" v-slot="{ open }">
        <div>
            <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap">
                {{ currentTimeframe }}
                <ChevronDownIcon :class="['text-sm transition-transform ease-linear -mr-1 h-5 w-5 text-gray-700 origin-center', open ? '[transform:rotateX(180deg)]' : null]"/>
            </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="z-10 absolute top-12 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                    <MenuItem v-for="item in timeframes" v-slot="{ active }" :key="item">
                        <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" @click="emitTime(item)">
                            {{ item }}
                        </a>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
</script>

<script>
export default {
	data() {
		return {
			currentTimeframe: "24 hours",
			timeframes: ["24 hours", "7 days", "15 days", "30 days"]
		};
	},
	methods: {
		emitTime(data) {
			this.currentTimeframe = data;

			this.$emit("set-timestamp", data);
		}
	},
	watch: {
		currentTimeframe(newTimeframe) {
			this.emitTime(newTimeframe);
		}
	}
};
</script>
